import React from 'react';
import moment from 'moment';
import './style.scss';
import axios from "axios";
import toast, {Toaster} from 'react-hot-toast';

class GuestUploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      arrivalDate: '',
      departureDate:'',
      roomNumber:'',
      arrivalInputType: 'text',
      departureInputType: 'text',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleArrivalDateFocus = () => {
    this.setState({ arrivalInputType: 'date' });
  };
  adjustDateByDays = (baseDate, days) => {
    let resultDate = moment(baseDate, "YYYY-MM-DDTHH:mm:ss.SSS");
    resultDate = resultDate.add(parseInt(days, 10), 'days');
    return resultDate.format("YYYY-MM-DDTHH:mm:ss.SSS");
  };
  handleArrivalDateBlur = () => {
    this.setState({ arrivalInputType: 'text' });
  };
  
  handleDepartureDateFocus = () => {
    this.setState({ departureInputType: 'date' });
  };
  
  handleDepartureDateBlur = () => {
    this.setState({ departureInputType: 'text' });
  };
  
  handleChange(event) {
    const { name, value } = event.target;
    
    // If the field is 'phoneNumber', sanitize the input
    if (name === 'phoneNumber') {
      // Use a regular expression to keep only digits and certain characters
      const sanitizedValue = value.replace(/[^\d+() \-]/g, '');
      
      // Update the state with the sanitized and formatted value
      this.setState({ [name]: sanitizedValue });
    } else {
      // For other fields, update the state as usual
      this.setState({ [name]: value });
    }
  }
  
  sanitizePhoneNumber(input) {
    // Remove any non-digit, non-plus characters
    const sanitizedValue = input.replace(/[^+\d]/g, '');
    
    // Ensure +1 at the beginning if not already there
    const formattedValue = sanitizedValue.startsWith('+') ? sanitizedValue : `+1${sanitizedValue}`;
    
    return formattedValue;
  }
  
  convertDate(dateString) {
    if (dateString) {
      const formattedDate = moment(dateString).format("MM/DD/YYYY");
      return formattedDate
    } else {
      return '';
    }
  }
  
  alertToast = (message, color, icon) => {
    toast((t) => (
      <div>
        <span onClick={() => toast.dismiss(t.id)}>
          {message}
        </span>
      </div>
    ), {
      position: 'top-right',
      icon: icon,
      duration: 3000,
      style: {
        background: color,
        color: '#ffffff',
      }
    });
  }
  dateValidate(date){
    return !isNaN(new Date(date));
  }
  async handleSubmit(event) {
    event.preventDefault();
    try {
      const hotelId = localStorage.getItem('marie_hotel_id');
      const sanitizedPhoneNumber = this.sanitizePhoneNumber(this.state.phoneNumber);
      const hotelData = (await axios.get(`/api/hotel/${hotelId}/hotel-data`)).data.hotelData;
      const scheduledActions = hotelData.ScheduledActions;
      const addGuestBody = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: sanitizedPhoneNumber,
        checkinDate: this.state.arrivalDate,
        checkoutDate: this.state.departureDate,
        roomNumber: this.state.roomNumber
      }
      Object.keys(scheduledActions).forEach(actionKey => {
        const action = scheduledActions[actionKey];
        const time_delta = action.time_delta;
        const time_hours = action.time;
        let baseDate, adjustedDate;
        if(action.base_date == 'arrival'){
          baseDate = `${this.state.arrivalDate}T${time_hours}:00.00`;
          adjustedDate = this.adjustDateByDays(baseDate, time_delta);
          addGuestBody[actionKey] = adjustedDate;  
        }else if(action.base_date == 'departure'){
          baseDate = `${this.state.departureDate}T${time_hours}:00.00`;
          adjustedDate = this.adjustDateByDays(baseDate, time_delta);
          addGuestBody[actionKey] = adjustedDate;
        }
      });

      if(addGuestBody.phoneNumber == '+1'){
        this.alertToast("Please fill in a Phone Number", '#CF4343', '⚠️');
        return;
      }
      if(!this.dateValidate(addGuestBody.checkinDate)){
        this.alertToast("Please fill in the Arrival Date", '#CF4343', '⚠️');
        return;
      }
      if(!this.dateValidate(addGuestBody.checkoutDate)){
        this.alertToast("Please fill in the Departure Date", '#CF4343', '⚠️');
        return;
      }
      const addGuestResponse = await axios.post(`/api/guests/${hotelId}/add-single-guest`, addGuestBody);
      if(addGuestBody.firstName != ''){
        this.props.onFormDataSubmit(addGuestBody);
        this.alertToast(addGuestBody.firstName + " was added successfully.", '#01a0c6', '✓');
      }else{
        this.props.onFormDataSubmit(addGuestBody);
        this.alertToast("The guest was added successfully.", '#01a0c6', '✓');
      }
    } catch (error) {
      console.error('there was an error sending the message', error)
      this.alertToast("Unable to add guest.", '#cc0000', '⚠️');
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="input-wrapper">
          <input 
            type="text"
            name="firstName" 
            value={this.state.firstName} 
            onChange={this.handleChange} 
            placeholder="Guest First Name" />
          <input 
            type="text" 
            name="lastName"
            value={this.state.lastName} 
            onChange={this.handleChange} 
            placeholder="Guest Last Name" />
            
        </div>
        <div className="input-wrapper">
          <input 
            type="tel" 
            value={this.state.phoneNumber}
            name="phoneNumber"
            onChange={this.handleChange} 
            placeholder="Guest Phone Number" />
          <input 
              type="text" 
              value={this.state.roomNumber}
              name="roomNumber"
              onChange={this.handleChange} 
              placeholder="Guest Room Number" />
        </div>
        <div className="input-wrapper">
          <input 
            placeholder="Arrival Date"
            type={this.state.arrivalInputType}
            onFocus={this.handleArrivalDateFocus}
            onBlur={this.handleArrivalDateBlur}
            name="arrivalDate"
            value={this.state.arrivalInputType == 'date' ? this.state.arrivalDate : this.convertDate(this.state.arrivalDate)}
            onChange={this.handleChange} />
          <input 
            placeholder="Departure Date"
            type={this.state.departureInputType}
            onFocus={this.handleDepartureDateFocus}
            onBlur={this.handleDepartureDateBlur}
            name="departureDate"
            value={this.state.departureInputType == 'date' ? this.state.departureDate : this.convertDate(this.state.departureDate)} 
            onChange={this.handleChange} />
        </div>
        <div className='submit-wrapper'>
          <div className="add-guest-wrapper">
            {/* <div className="spacer"></div> */}
            <input type="submit" value="Add Guest"/>
          </div>
        </div>
      </form>
    );
  }
}

export default GuestUploadForm