import React, { useEffect, useState, useRef } from "react";
import Conversation from "../InboxPanel/Conversation/Conversation";
import Sparkle from '../../assets/google-response.png';
import axios from "axios";
import './style.scss';
import toast, {Toaster} from 'react-hot-toast';
import BackButton from "../../assets/back-button.png";
import Lock from "../../assets/lock.png";
import PopupTextBox from "../PopupTextBox/PopupTextBox";

const SideChat = ({data, id, constructor, conversation, guestPhoneNumber, removeFromManualConversations, addToManualConversations, toggleManualSwitch, isInManualMode, toggleBlockOverlay, handleBlockUnblockClick, buttonFunction, buttonStyle, buttonLabel, showBlockOverlay, isBlocked, toggleSideChat, isOpen}) => {
    const [manualMode, setManualMode] = useState(isInManualMode);
    const [checkIsBlocked, setCheckedIsBlocked] = useState(isBlocked);
    const [aiGeneratedResponse, setAiGeneratedResponse] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(true);
    const [formData, setFormData] = useState(data);
    const [editMode, setEditMode] = useState(false);
    const popupRef = useRef(null);
    const reviewId = data?.id;
    const label = buttonLabel;
    const [isDisabled, setIsDisabled] = useState(() => {
        const storedValue = localStorage.getItem("review_response_disabled");
        return storedValue === "true";
    });
    const [reviewResponse, setReviewResponse] = useState(null);
    const hotelId = localStorage.getItem('marie_hotel_id');


    const handleChange = (event) => {
        setAiGeneratedResponse(event.target.value);
    };
    const extractNumerator = (rating) => {
        const regex = /^(\d+(\.\d+)?)\/(5|10)$/;
        if(typeof rating == 'string'){
            const match = rating.match(regex);
            if (match) {
                let numerator = parseFloat(match[1]);
                const denominator = parseInt(match[3], 10);
                if (denominator === 10) {
                    numerator = numerator / 2;
                }
                return numerator;
            }
            return 0;
        }
    };
    const toggleSideChatAndBackButtonClicked = () => {
        toggleSideChat();
    };

    const sendReviewResponse = async() => {
        setIsButtonDisabled(true);
        const url = "/api/reviews/sendResponseToDatabase/";
        const hotelId = localStorage.getItem('marie_hotel_id');
        const guestData = data?.data;
        const guestName = guestData?.firstName + " " + guestData?.lastName;
        const reviewId = guestData?.id;
        const hotelResponse = aiGeneratedResponse;
        if(hotelResponse !== ''){
            try{
                const response = await axios.post(url, {
                    hotelResponse,
                    hotelId,
                    guestName,
                    id: reviewId
                });
                if(response.status !== 200){
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
                alertToast("Response Sent Successfully", "#01a0c6", "✓");
                fetchReviewResponse();
                setAiGeneratedResponse("");
            }catch(err){
                console.error(err.message);
                alertToast("Response Couldn't be Sent. Please Try Again", "CF4343", "⚠️")
                setIsButtonDisabled(false);
            }
        }else{
            alertToast("Message Body is Empty. Please Write a Message", "#CF4343", "⚠️");
            setIsButtonDisabled(false);
            return;
        }
    }
    const generateAiReviewResponse = async() => {
        setIsButtonDisabled(true);
        alertToast("Generating AI Response", "#01a0c6", "🚀");
        const guestData = data?.data
        const guestReview = guestData?.guestMessage;
        const rating = extractNumerator(guestData?.rating);
        const guestFirstName = guestData?.firstName;
        const url = "/api/reviews/sendReviewToBedrock/"
        try {
            const response = await axios.post(url, {
                guestMessage: guestReview,
                rating,
                guestFirstName
            });
            if (response.status !== 200) {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }
            const bedrockResponse = await response.data;
            const cleanedResponse = bedrockResponse?.data
            .replace(/[\[\]{}"\n]/g, "")
            .replace(/\\n/g, "\n")
            .replace(/response:/gi, "")
            .replace(/text:/gi, "")
            .replace(/\\r/g, "");
            setAiGeneratedResponse(cleanedResponse);
            alertToast("AI Response Generated", "#01a0c6", "✓");
            setIsButtonDisabled(false);
        } catch (error) {
            alertToast("Failed to Generate a Response. Please try again.", "#CF4343", "⚠️");
            setIsButtonDisabled(false);
            console.error("Error:", error.message);
        }
    }

    const alertToast = (message, color, icon) => {
        toast((t) => (
          <div>
            <span>
              {message}
            </span>
          </div>
        ), {
          position: 'top-right',
          icon: icon,
          duration: 3000,
          style: {
            background: color,
            color: '#ffffff',
          }
        });
      }
    function hideIfEmpty(value) {
        return !value ? { display: 'none' } : {};
    }
    const hideIfNotEmpty = (v1, v2) => {
        return (v1 || v2) ? { display: 'none'} : {};
    }

    function toggleIsInManualMode(){
        setManualMode(!manualMode);
        manualMode === false ? removeFromManualConversations() : addToManualConversations();
    }
    function toggleCheckIsBlocked() {
        setCheckedIsBlocked(!checkIsBlocked);
    }
    function changeDateFormat(checkinDate, checkoutDate) {
        if (checkinDate && checkoutDate) {
            let formattedCheckinDate = formatDate(checkinDate);
            let formattedCheckoutDate = formatDate(checkoutDate);
            return {
                checkinDate: formattedCheckinDate,
                checkoutDate: formattedCheckoutDate
            };
        }
        return {
            checkinDate: '',
            checkoutDate: ''
        };
    }

    function formatDate(dateString) {
        let date = new Date(dateString);
        let month = date.getMonth() + 1;
        let day = date.getDate() + 1;
        if((month === 4 || month === 6 || month === 9 || month === 11) && day > 30){
            month++;
            day = day - 30
        }else if((month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) && day > 31){
            month++;
            day = day - 31;
        }else if(month === 2 && day > 28){
            month++;
            day = day - 28;
        }
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        return month + '/' + day;
    }

    const fetchReviewResponse = async () => {
            const url = "/api/reviews/getReviewResponse";
            try {
                const response = await axios.get(url, {
                    params: {
                        hotelId,
                        id: reviewId,
                    },
                });
                if(response?.data?.data && Array.isArray(response.data.data) && response.data.data.length > 0){
                    const reviewResponse = response?.data?.data[0]?.hotelResponse
                    setReviewResponse(reviewResponse);
                } else {
                    setReviewResponse(null);
                }
            } catch (err) {
                console.error("Error fetching review response:", err);
                setReviewResponse(null);
            } 
    };
    useEffect(() => {
        fetchReviewResponse();
    }, [data, reviewId]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupVisible(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
  
    const handleButtonClick = () => {
        buttonFunction();
    };

    useEffect(() => {
        setFormData(data?.guestData);
    }, [data]);

    switch(constructor){
        case 'reviews':
            return(
                <>
                    {isOpen && (
                        <div className="chat-overlay" onClick={toggleSideChat}></div>
                    )}
                    <div className={`side-chat-body side-chat-reviews-body ${isOpen ? 'open' : ''}`}>
                        <div className="side-chat-content">
                            <div className="back-arrow-ctn">
                                <span onClick={toggleSideChat} className="side-chat-back-arrow"><img src={BackButton} className="side-chat-back-arrow"></img></span>
                            </div>
                            <div className="guest-name-ctn">
                                <div className='google-guest-name' style={hideIfEmpty(data?.data?.firstName)}>{data?.data?.firstName} {data?.data?.lastName}</div>
                                <div className='google-satisfaction-label' style={hideIfEmpty(data?.rating)}>Satisfaction Level</div>
                                <div className="google-stars">{extractNumerator(data?.data?.rating)}</div>
                            </div>
                            <div className='google-review-ctn'>
                                <div style={hideIfEmpty(data?.data?.feedback)}>
                                    <span className="google-review-label">Guest Review</span>
                                    <div className="google-review-message">
                                        {data?.data?.feedback}
                                    </div>
                                </div>
                                {isDisabled && !data?.data?.response && !reviewResponse ? (
                                    <>
                                        <div className="disable-body" 
                                            onMouseEnter={() => {
                                                setIsPopupVisible(true)
                                            }} 
                                            onMouseLeave={() => {
                                                setIsPopupVisible(true)
                                            }}>
                                            <div className={'disable-ctn disable-feature'}>
                                                <div className="google-review-response-ctn">
                                                    <div className="google-review-label">Your Response</div>
                                                    <textarea className='google-review-response-message google-review-textbox disabled'
                                                        placeholder={"Type Here..."}>
                                                    </textarea>
                                                </div>
                                                <div className="google-response-buttons-ctn">
                                                    <span className={`google-gpt-response-button left disabled`}>Generate Response <img className="sparkle-icon" src={Sparkle} alt="Sparkle icon" /></span>
                                                    <span className={`google-gpt-response-button right disabled`} style={{ ...{ backgroundColor: "#01a0c6" }}} >Send Response</span>
                                                </div>
                                                <div className="disable-icon" >
                                                    <img src={Lock} alt="Disabled" />
                                                </div>
                                            </div>
                                        </div>
                                        {isPopupVisible && (
                                            <div className="popup-textbox-container" ref={popupRef}>
                                                <PopupTextBox message="This Feature is Locked." linkUrl="mailto:sales@talsey.com" linkText="Learn More" />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="google-review-response-ctn" style={hideIfEmpty((data?.data?.response || reviewResponse))}>
                                            <span className="google-review-label">Your Response</span>
                                            <div className="google-review-response-message">
                                                {!data?.data?.response ? reviewResponse : data?.data?.response}
                                            </div>
                                        </div>
                                        <div className="google-review-response-ctn" style={hideIfNotEmpty(data?.data?.response, reviewResponse)}>
                                            <div className="google-review-label">Your Response</div>
                                            <textarea className={`google-review-response-message google-review-textbox`}
                                                value={aiGeneratedResponse}
                                                onChange={handleChange}
                                                placeholder={"Type Here..."}>
                                            </textarea>
                                        </div>
                                        <div className="google-response-buttons-ctn">
                                            <span className={`google-gpt-response-button left`} onClick={!isButtonDisabled ? generateAiReviewResponse : null} style={hideIfNotEmpty(data?.data?.response, reviewResponse)}>Generate Response <img className="sparkle-icon" src={Sparkle} alt="Sparkle icon" /></span>
                                            <span className={`google-gpt-response-button right`} onClick={!isButtonDisabled ? sendReviewResponse : null} style={{ ...{ backgroundColor: "#01a0c6" }, ...hideIfNotEmpty(data?.data?.response, reviewResponse) }} >Send Response</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )
        case 'feedback':
            return(
                <>
                    {isOpen && (
                        <div className="chat-overlay" onClick={toggleSideChat}></div>
                    )}
                    <div className={`side-chat-body side-chat-feedback-body ${isOpen ? 'open' : ''}`}>
                        <div className="side-chat-content">
                            <div className="back-arrow-ctn">
                                <span onClick={toggleSideChat} className="side-chat-back-arrow"><img src={BackButton} className="side-chat-back-arrow"></img></span>
                            </div>
                            <div className="guest-name-ctn">
                                <div className='google-guest-name' style={hideIfEmpty(data?.data?.first_name)}>{data?.data?.first_name} {data?.data?.last_name}</div>
                                <div className="google-guest-phone-number" style={hideIfEmpty(data?.data?.phone_number)}>{data?.data?.phone_number}</div>
                                <div className="google-guest-email" style={hideIfEmpty(data?.data?.email)}>{data?.data?.email}</div>
                                {data?.data?.questions && (
                                    <>
                                        <div className='google-satisfaction-label'>
                                            Satisfaction Level
                                        </div>
                                        <div className="google-stars">
                                            {data?.data?.questions[0].response}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='google-review-ctn'>
                            <div style={hideIfEmpty(data?.data?.questions)}>
                            <span className="google-review-label"></span>
                            {data?.data?.questions && data?.data?.questions.slice(1).map((questionObj, index) => (
                                <div key={index}>
                                    <div className="leads-message">
                                        <div>{questionObj.question}</div>
                                    </div>
                                    <div  className="feedback-response-message">
                                        <div>{questionObj.response}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                            </div>
                            <div className="google-response-buttons-ctn">
                                <span className="google-gpt-response-button right" onClick={handleButtonClick} style={buttonStyle}>{label("Issued", "Not Issued")}</span>
                            </div>
                        </div>
                    </div>
                </>
            )
        case 'conversations':
            return(
                <>
                    {isOpen && (
                        <div className="chat-overlay" onClick={toggleSideChat}></div>
                    )}
                    <div className={`side-chat-body side-chat-conversations-body ${isOpen ? 'open' : ''}`}>
                        <div className="side-chat-content">
                            <div className="side-chat-top-row">
                                <div className="back-arrow-ctn">
                                    <span onClick={toggleSideChat} className="side-chat-back-arrow"><img src={BackButton} className="side-chat-back-arrow"></img></span>
                                </div>
                                <div className="manual-switch-container">
                                    <span className="manual-switch-label">Manual Mode</span>
                                    <div className="just-switch-container">
                                        <label className="switch manual-switch">
                                            <input type="checkbox" onClick={toggleIsInManualMode} onChange={toggleManualSwitch} checked={manualMode} />
                                            <span className="slider round"></span>
                                        </label>
                                        <div className="block-switch-container">
                                            <div className="block-switch-label" onClick={toggleBlockOverlay}>
                                                <span className="block-switch-label-text">&#xFE19;</span>
                                            </div>
                                            {showBlockOverlay && (
                                                <div className="block-overlay">
                                                    <span className="block-overlay-text" onClick={handleBlockUnblockClick} onChange={toggleCheckIsBlocked}>
                                                        {isBlocked ? "Unblock User" : "Block User"}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="guest-name-ctn">
                                <div className="name-switch-ctn">
                                    <div className="name-number-ctn">
                                        <div className='conversation-guest-name'>{data?.guestName}</div>
                                        <div className="conversations-guest-phone-number">{data?.guestPhoneNumber}</div>
                                        <div className="conversations-guest-phone-number" style={hideIfEmpty(data?.roomNumber)}>{data?.roomNumber}</div>
                                    </div>
                                </div>
                            </div>
                            <Conversation 
                                conversation={conversation}
                                hotelId={hotelId}
                                guestPhoneNumber={guestPhoneNumber}
                                removeFromManualConversations={removeFromManualConversations}
                                addToManualConversations={addToManualConversations}
                            />
                        </div>
                    </div>
                </>
        )
        case 'leads':
            return(
                <>
                    {isOpen && (
                        <div className="chat-overlay" onClick={toggleSideChat}></div>
                    )}
                    <div className={`side-chat-body side-chat-feedback-body ${isOpen ? 'open' : ''}`}>
                        <div className="side-chat-content">
                            <div className="back-arrow-ctn">
                                <span onClick={toggleSideChat} className="side-chat-back-arrow"><img src={BackButton} className="side-chat-back-arrow"></img></span>
                            </div>
                            <div className="guest-name-ctn">
                                <div className='google-guest-name' style={hideIfEmpty(data?.data?.first_name)}>{data?.data?.first_name} {data?.data?.last_name}</div>
                                <div className="google-guest-phone-number" style={hideIfEmpty(data?.data?.phone_number)}>{data?.data?.phone_number}</div>
                                <div className="google-guest-email" style={hideIfEmpty(data?.data?.email)}>{data?.data?.email}</div>
                            </div>
                            <div className='google-review-ctn'>
                                <div style={hideIfEmpty(data?.data?.questions)}>
                                    <span className="google-review-label"></span>
                                    {data?.data?.questions && data?.data?.questions.slice(0).map((questionObj, index) => (
                                        <div key={index}>
                                            <div className="leads-message">
                                                <div>{questionObj.question}</div>
                                            </div>
                                            <div className="leads-response-message">
                                                {questionObj.response === 'true' ? (
                                                    <div>Yes</div>
                                                ): questionObj.response === 'false' ? (
                                                    <div>No</div>
                                                ):
                                                <div>{questionObj.response}</div>
                                            }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="google-response-buttons-ctn">
                                <span className="google-gpt-response-button right" onClick={handleButtonClick} style={buttonStyle}>{label('Contacted', 'Not Contacted')}</span>
                            </div>
                        </div>
                    </div>
                </>
            )
        case 'arrivals':
            const toggleEditMode = () => {
                setEditMode(!editMode);
            };
            const handleArrivalEdit = (e) => {
                const {name, value} = e.target;
                setFormData(prev => ({ ...prev, [name]: value}));
            };
            const cancelSubmit = () => {
                setEditMode(false);
            }
            const cancelAndToggle = () => {
                toggleSideChat();
                setEditMode(false);
            }
            const handleSubmit = async() => {
                const url = `/api/guests/${hotelId}/update-guest`;
                const body = {
                    guestData: formData
                };
                const response = await axios.post(url, body);
                if(response.data.success === true){
                    alertToast("Guest Edit Successful", "#01a0c6", "✓")
                    setEditMode(false);
                }else{
                    alertToast("Guest Edit Failed. Please try again.", "#CF4343", "⚠️")
                }
            };
            const dates = changeDateFormat(formData?.checkinDate, formData?.checkoutDate);
            return(
                <>
                    {isOpen && (
                        <div className="chat-overlay" onClick={cancelAndToggle}></div>
                    )}
                    <div className={`side-chat-body side-chat-feedback-body ${isOpen ? 'open' : ''}`}>
                        <div className="side-chat-content">
                            <div className="back-arrow-ctn">
                                <span onClick={cancelAndToggle} className="side-chat-back-arrow"><img src={BackButton} className="side-chat-back-arrow"></img></span>
                                {editMode ? (
                                    <>
                                        <div>
                                            <span onClick={cancelSubmit} className='cancel-arrival-edit'>Cancel</span>
                                            <span onClick={handleSubmit} className='edit-button'>Submit</span>
                                        </div>
                                    </>
                                ) : (
                                    <span onClick={toggleEditMode} className='edit-button'>Edit</span>
                                )}
                            </div>
                            <div className="guest-name-ctn">
                                {editMode ? (
                                    <>
                                        <div className="sidechat-arrival-ctn">
                                            <div className='arrival-guest-name'>{formData?.Name?.First} {formData?.Name?.Last}</div>
                                            <div className='arrival-room edit-mode'>Room 
                                                <input
                                                    type="text"
                                                    name="roomNumber"
                                                    placeholder="Room Number"
                                                    value={formData?.roomNumber}
                                                    onChange={handleArrivalEdit}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                <>
                                    <div className="sidechat-arrival-ctn">
                                        <div className='arrival-guest-name'>{formData?.Name?.First} {data?.guestData?.Name?.Last}</div>
                                        <div className="arrival-room">Room {formData?.roomNumber}</div>
                                    </div>
                                </>
                                )}
                                <div className="arrival-phone-number">{formData?.PhoneNumber}</div>
                                <div className="arrival-checkin-checkout-date">{dates.checkinDate} - {dates.checkoutDate}</div>
                            </div>
                        </div>
                    </div>
                </>
            )
    }
}

export default SideChat

