import React from "react";
import "./style.scss";
import axios from "axios";
import ArrivalEntry from "./ArrivalEntry/ArrivalEntry";

const GuestListHeader = () => {
    return (
      <div className="guest-list-body">
        <div className="guest-list-header">
          <div className="row-header-container">
            <div className="row-header">Name</div>
          </div>
          <div className="row-header-container">
            <div className="row-header">Arrival</div>
          </div>
          <div className="row-header-container">
            <div className="row-header">Departure</div>
          </div>
          <div className="row-header-container">
            <div className="row-header">Phone</div>
          </div>
          <div className='row-header-container'>
            <div className='row-header'>Room Number</div>
          </div>
          <div className='row-header-container'>
            <div className='row-header'></div>
          </div>
        </div>
      </div>
      
    );
  };

export default GuestListHeader;