import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import axios from 'axios';
import GuestListHeader from './GuestListHeader/GuestListHeader';
import GuestUpload from './GuestUpload/GuestUpload';
import ArrivalEntry from './GuestListHeader/ArrivalEntry/ArrivalEntry';
import PaginationFooter from '../PaginationFooter/PaginationFooter';

const LIST_SIZE = 10;

const ArrivalsPanel = () => {
    const [guests, setGuests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);


    function getUniqueConversationPreviews() {
        return guests.length;
	}
    function dateValidate(date){
        return !isNaN(new Date(date));
    }
    const loadGuests = async () => {
        try {
            const hotelId = localStorage.getItem('marie_hotel_id');
            const response = await axios.get(`/api/guests/${hotelId}/all`);
            const sanitizedGuests = response.data.filter(guest => 
                guest.PhoneNumber && 
                guest.PhoneNumber !== "toArray" &&
                dateValidate(guest.checkinDate)
                );
            sanitizedGuests.sort((a, b) => a.checkinDate.localeCompare(b.checkinDate));
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);
            const upcomingGuests = sanitizedGuests.filter(guest => {;
                const [year, month, day] = guest.checkinDate.split("-")
                const guestCheckInDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
                return guestCheckInDate >= today;

            });
            setGuests(upcomingGuests);
        } catch (error) {
            console.error('Failed to fetch guests', error);
        }
    };
    const addGuestEntry = useCallback((guestData) => {
        const transformedGuestData = {
            ...guestData,
            Name: {
                First: guestData.firstName,
                Last: guestData.lastName,
            },
            PhoneNumber: guestData.phoneNumber,
            checkinDate: guestData.checkinDate,
            checkoutDate: guestData.checkoutDate,
            roomNumber: guestData.roomNumber
        };
    
        setGuests(prevGuests => [...prevGuests, transformedGuestData]);
    });
    
    const handleDeleteGuest = (phoneNumber) => {
        setGuests(guests.filter(guest => guest.PhoneNumber !== phoneNumber));
    };

    useEffect(() => {
        loadGuests();
    },[]);

    return (
        <div className="arrivals-panel-body">
            <div className="arrivals-panel-content">
                <div className='arrivals-panel-header'>
                    <div className="arrivals-panel-title">Arrivals</div>
                </div>
                <div className="add-guest-header">Add Guest</div>
                <div className="guest-upload-container">
                    <GuestUpload onGuestAdd={addGuestEntry}></GuestUpload>
                </div>
                <div className="arrivals-header">Upcoming Arrivals</div>
                <GuestListHeader></GuestListHeader>
                <div className="guest-list-container">
                    {guests.slice((currentPage - 1) * LIST_SIZE, currentPage * LIST_SIZE).map((guest, index) => (
                        <ArrivalEntry key={index} guestData={guest} onDelete={handleDeleteGuest}/>
                    ))}
                <PaginationFooter
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    numberOfTotalPreviews={getUniqueConversationPreviews()}
                    numberOfPreviews={currentPage}
                    pageSize={12}
                />
                </div>
            </div>
        </div>
    );
};

export default ArrivalsPanel;