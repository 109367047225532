import React from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import LeadsPanel from '../../components/LeadsPanel/LeadsPanel';

function LeadsPage({ chatToken, hotelId }) {
    return (
      <div className="arrivals-body">
        <ControlPanel page="leads"></ControlPanel>
        <LeadsPanel></LeadsPanel>
      </div>
    )
  }

export default LeadsPage;