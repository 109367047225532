import React from "react";
import "./style.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import SideChat from "../../../SideChat/SideChat";

const ArrivalEntry = ({ guestData, onDelete }) => {
    const { Name, PhoneNumber, checkinDate, checkoutDate, roomNumber} = guestData;
    const arrivalDate = checkinDate.replace(/T.*$/, "");
    const departureDate = checkoutDate.replace(/T.*$/, "");
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const toggleSideChat = () => {
      // if (!isChatOpen) {
      //     setSelectedData({
      //         guestData
      //     });
      // }
      // setIsChatOpen(!isChatOpen);
  };
    async function deleteEntry(){
      try{
        const hotelId = localStorage.getItem('marie_hotel_id')
        const response = await axios.delete(`/api/guests/${hotelId}/${PhoneNumber}/delete`);
        onDelete(PhoneNumber);
      } catch (error) {
            console.error('Failed to delete guest', error);
      }
    }
    return (
      <>
        <div className="arrival-entry-row" >
          <div className="arrival-container" onClick={toggleSideChat}>
            <div className="arrival-label">{Name?.First} {Name?.Last}</div>
          </div>
          <div className="arrival-container" onClick={toggleSideChat}>
            <div className="arrival-label">{arrivalDate}</div>
          </div>
          <div className="arrival-container" onClick={toggleSideChat}>
            <div className="arrival-label">{departureDate}</div>
          </div>
          <div className="arrival-container" onClick={toggleSideChat}>
            <div className="arrival-label">{PhoneNumber}</div>
          </div>
          <div className="arrival-container" onClick={toggleSideChat}>
            {roomNumber ? (
              <div className='arrival-label'>{roomNumber}</div>
            ):(
              <div className='arrival-label'>n/a</div>
            )}
          </div>
          <div className="delete-button-container">
            <div className="delete-button" onClick={deleteEntry}>Delete</div>
          </div>
        </div>
        <SideChat 
          data={selectedData}
          constructor={'arrivals'}
          id={PhoneNumber}
          isOpen={isChatOpen} 
          toggleSideChat={toggleSideChat} 
        />
      </>
    );
  };

export default ArrivalEntry;