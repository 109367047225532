import React from "react";
import useEffect from "react";
import paperClip from "../../../assets/paper-clip.png";
import trashCan from "../../../assets/trash-can-icon.png";
import "./style.scss";
import { animateScroll } from "react-scroll";
import axios from "axios";

class Conversation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			conversation: props.conversation,
			guestPhoneNumber: props.guestPhoneNumber,
			formData: null,
			attachedFiles: [],
			messages: [],
			newMessage: "",
			mediaTempUrls: {},
			hotelId: props.hotelId,
			addToManualConversations: props.addToManualConversations,
			removeFromManualConversations: props.removeFromManualConversations,
		};
	}
	
	componentDidMount = () => {
		this.initConversation();
	};

	componentDidUpdate(prevProps) {
		if (prevProps.conversation !== this.props.conversation) {
			this.setState(
				{ conversation: this.props.conversation },
				this.initConversation
			);
		}
	}

	scrollToBottom = () => {
		const element = document.getElementById("conversation-messages-row");
		element.scrollTop = element.scrollHeight;
	};

	scrollToBottomSmooth = () => {
		animateScroll.scrollToBottom({
			containerId: "conversation-messages-row",
			duration: 300,
		});
	};

	formatDateToCustomString = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
	}

	parseMediaMessages = async (messages) => {
		for (let message of messages) {
			if (message.state.type === "media") {
				const tempUrl = await message.state.medias[0].getContentTemporaryUrl();
				let newTempUrls = this.state.mediaTempUrls;
				this.state.mediaTempUrls[message.state.sid] = tempUrl;
				this.setState(
					{
						mediaTempUrls: newTempUrls,
					},
					() => this.scrollToBottom
				);
			}
		}
	};

	initConversation = async () => {
		this.state.conversation
			.getMessages()
			.then(async (messagePaginator) => {
				while (true) {
					this.parseMediaMessages(messagePaginator.items);
					this.setState(
						(prevState) => ({
							messages: [...messagePaginator.items, ...prevState.messages],
						}),
						this.scrollToBottom
					);

					if (messagePaginator.hasPrevPage) {
						messagePaginator = await messagePaginator.prevPage();
					} else {
						return;
					}
				}
			})
			.catch((err) => {
				console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
				this.setState({ loadingState: "failed" });
			});
		this.state.conversation.on("messageAdded", (message) => {
			this.parseMediaMessages([message]);
			this.setState(
				(prevState) => ({
					messages: [...prevState.messages, message],
				}),
				this.scrollToBottomSmooth
			);
		});
	};

	mediaMessageDebugging = async (messages) => {
		for (let message of messages) {
			if (message.state.type === "media") {
				for (let media of message.state.medias) {
					console.log(await media.getContentTemporaryUrl());
				}
			}
		}
	};

	isMessageFromUser = (message) => {
		return message.state.author === this.state.hotelId;
	};

	getMostRecentTimestamp = () => {
		if (this.state.messages.length > 0) {
			const options = {
				hour: "numeric",
				minute: "numeric",
				hour12: true,
				month: "short",
				day: "numeric",
				year: "numeric",
			};
			const latestTimestamp =
				this.state.messages[this.state.messages.length - 1].state.timestamp;
			return latestTimestamp.toLocaleString("en-US", options);
		} else {
			return "Loading...";
		}
	};

	handleIndividualTimestamp = (timestamp) => {
		const options = {
			month: "short",
			day: "numeric",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		};
		let formattedTimestamp = timestamp.toLocaleString("en-US", options);
		// removes the comma after the day
		formattedTimestamp = formattedTimestamp.replace(",", "");
		// Adds the period after the abbreviated month except "May".
		formattedTimestamp = formattedTimestamp.replace(
			/\b(?:(Jan|Feb|Mar|Apr|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\b)/,
			"$1."
		);
		return formattedTimestamp;
	};

	sendHotelMessageToGuestHistory = async (message) => {
		const allGuests = await axios.get(`/api/guests/${this.state.hotelId}/all`)
		const guest = allGuests.data.find(guest => guest.PhoneNumber === this.state.guestPhoneNumber)

		const defaultMessage = 'n/a'
		const newDate = this.formatDateToCustomString(new Date())

		const formatHistory = (message) => ({
			date: this.formatDateToCustomString(message.state.timestamp) || newDate,
			message: message.state.body || defaultMessage
		})

		const guestMessages = this.state.messages
			.filter((msg) => msg.state.author === this.state.guestPhoneNumber)
			.map(formatHistory)

		const hotelMessages = this.state.messages
			.filter((msg) => msg.state.author === this.state.hotelId)
			.map(formatHistory)

		const defaultChat = { user: guestMessages, hotel: hotelMessages }
		let prevChat = defaultChat

		if (guest.chat) {
			const chatHotel = guest.chat.hotel || []

			prevChat = chatHotel.length > defaultChat.hotel.length ? guest.chat : defaultChat
		}
		const updatedGuest = {
			...guest,
			firstName: guest.Name.First,
			lastName: guest.Name.Last,
			phoneNumber: this.state.guestPhoneNumber,
			chat: { ...prevChat, hotel: [...prevChat.hotel, { date: newDate, message }] },
		}

		await axios
			.post(`/api/guests/${this.state.hotelId}/add-single-guest`, updatedGuest)	
	}


	sendMessage = async () => {
		const message = this.state.newMessage;
		const formData = this.state.formData;
		if (message || formData) {
			if (message) {
				this.setState({ newMessage: "" });
				this.state.conversation.sendMessage(message);
				await this.sendHotelMessageToGuestHistory(message)
			}
			if (formData) {
				this.setState({ formData: null });
				this.state.conversation.sendMessage(formData);
			}
			const manualEndsTimestamp = new Date(
				Date.now() + 1 * 60 * 1000 * 15
			).getTime() // TODO: make configurable
			this.state.removeFromManualConversations(this.state.conversation.sid);
			this.state.addToManualConversations(
				this.state.conversation.sid,
				manualEndsTimestamp
			);
			axios.post("/api/chat/set-to-manual", {
				hotelId: this.state.hotelId,
				guestPhoneNumber: this.state.guestPhoneNumber,
				timestamp: manualEndsTimestamp,
			})
		}
	};

	handleTextAreaChange = (event) => {
		this.setState({ newMessage: event.target.value });
	};

	handleKeyDown = (event) => {
		// TODO: Use Twilio markup language to allow extra lines?
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			this.sendMessage();
		}
	};

	onFileChange = (event) => {
		const formData = new FormData();
		formData.append("file", event.target.files[0]);
		this.setState({
			formData: formData,
			attachedFiles: event.target.files[0].name
		});
		event.target.value = "";
	};

	mapChatMessages = (chatMessages, options = { isFromTwilio: false }) => {
		const { isFromTwilio } = options

		const olderMessages = []
		const newerMessages = []

		chatMessages.forEach((message) => {
			let parsedDate = new Date()

			if (isFromTwilio) {
				parsedDate = new Date(message.state.timestamp)
			} else {
				parsedDate = new Date(message.date)
			}

			const tenDaysInMillis = 10 * 24 * 60 * 60 * 1000; // Define milliseconds for ten days

			const currentDate = new Date();
			const dateDifference = currentDate - parsedDate;

			if (dateDifference >= tenDaysInMillis) {
				olderMessages.push(message)
			} else {
				newerMessages.push(message)
			}
		})

		return ({ olderMessages, newerMessages });
	};


	renderUserMessage = (message) => {
		if (message.state.type === "media") {
			const tempUrl = this.state.mediaTempUrls[message.state.sid];
			if (tempUrl) {
				return (
					<>
						<div className="message-row" key={message.state.sid}>
							<div className="spacer"></div>
							<img src={tempUrl} className="message-image from"></img>
						</div>
						<div className="blue-timestamp">
							{this.handleIndividualTimestamp(message.state.timestamp)}
						</div>
					</>
				);
			}
		} else {
			const userMessages = this.mapChatMessages(this.state.messages, { isFromTwilio: true })
			return (
				<>
					<div className="message-row" key={message.state.sid}>
						<div className="spacer"></div>
						<div className="message from">{message.state.body}</div>
					</div>
					<div className="blue-timestamp">
						{this.handleIndividualTimestamp(message.state.timestamp)}
					</div>
				</>
			);
		}
	};

	renderGuestMessage = (message) => {
		if (message.state.type === "media") {
			const tempUrl = this.state.mediaTempUrls[message.state.sid];
			if (tempUrl) {
				return (
					<>
						<div className="message-row" key={message.state.sid}>
							<img src={tempUrl} className="message-image to"></img>
							<div className="spacer"></div>
						</div>
						<div className="gray-timestamp">
							{this.handleIndividualTimestamp(message.state.timestamp)}
						</div>
					</>
				);
			}
		} else {
			return (
				<>
					<div className="message-row" key={message.state.sid}>
						<div className="message to">{message.state.body}</div>
						<div className="spacer"></div>
					</div>
					<div className="gray-timestamp">
						{this.handleIndividualTimestamp(message.state.timestamp)}
					</div>
				</>
			);
		}
	};

	handleAttachButtonClick = () => {
		document.getElementById("attach-input").click();
	};

	deleteAttachment = () => {
		this.setState({
			formData: null,
		});
	};

	// TODO: Don't interrupt scroll if typing starts

	render() {
		return (
			<div className="conversation-body">

				<div className="conversation-content-body">
					<div
						id="conversation-messages-row"
						className="conversation-messages-row"
					>
						{this.state.messages.map((message) => {
							return this.isMessageFromUser(message)
								? this.renderUserMessage(message)
								: this.renderGuestMessage(message);
						})}
					</div>
					<div className="conversation-user-input">
						<div className="conversation-attachment-row">
							{this.state.formData && (
								<div className="attachment">
									<span>{this.state.attachedFiles}</span>
									<img
										className="trash-can-image"
										src={trashCan}
										onClick={this.deleteAttachment}
									></img>
								</div>

								// <button className="attach-button">
								// 	<img
								// 		className="trash-can-image"
								// 		src={trashCan}
								// 		onClick={this.deleteAttachment}
								// 	></img>
								// </button>
							)}
						</div>
						<div className="conversation-buttons-row">
							<input
								id="attach-input"
								type="file"
								accept="image/*"
								onChange={this.onFileChange}
							/>
							<button className="attach-button">
								<img
									className="paper-clip-image"
									src={paperClip}
									onClick={this.handleAttachButtonClick}
								></img>
							</button>
							<div className="conversation-input-row">
								<textarea
									className="input"
									placeholder="Type here..."
									value={this.state.newMessage}
									onChange={this.handleTextAreaChange}
									onKeyDown={this.handleKeyDown}
								></textarea>
							</div>
							<button className="send-button" onClick={this.sendMessage}>
								Send
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Conversation;
