import React from 'react';
import './style.scss';

function MessagePreview({ id, name, phoneNumber, messageBody, timestamp, unread, unixTimestamp, displayConversation, lastMessages}) {
  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  }

  const getBodyMessage = (messages) => {
    return `"${messages[messages.length - 1]?.state?.body}"`
  }

  const getTimestamp = (messages) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    const latestTimestamp = messages[messages.length - 1]?.state?.timestamp

    return `${latestTimestamp?.toLocaleString("en-US", options)}`
  }

  const generateBody = messageBody => {
    if (!messageBody || messageBody === '"undefined"') return ('')

    if (messageBody.length > 100) {
      const shortString = messageBody.slice(0,99)
      return shortString + '..."'
    } else {
      return messageBody
    }
  }

  return (
    <div className="message-preview-centering-container">
      <div className={unread ? "message-preview-body" : "message-preview-body read"} onClick={() => displayConversation(id, name, phoneNumber, unixTimestamp)}>
        <div className= "guest-conversations-name-container">
          <span className="guest-conversations-name">{name}</span>
        </div>
        <div className= "guest-conversations-phone-container">
          <span className="guest-conversations-phone-number">{formatPhoneNumber(phoneNumber)}</span>
        </div>
        <div className= "guest-conversations-preview-container">
          <div className="guest-conversations-conversation-preview">
            {generateBody(getBodyMessage(lastMessages))}
          </div>
        </div>
        <div className= "guest-conversations-timestamp-container">
          <div className="guest-conversations-conversation-timestamp">
            {(getTimestamp(lastMessages)) ? getTimestamp(lastMessages) : timestamp}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagePreview;