import React, { useState, useEffect } from 'react';
import './style.scss';
import axios from 'axios';
import Table from '../Table/Table';
import Snapshot from '../Snapshot/Snapshot';

const ReviewsPanel = () => {
    const [reviews, setReviews] = useState([]);
    const [avgReviewScore, setAvgReviewScore] = useState(0);
    const [typeFormsData, setTypeFormsData] = useState([]);
    const [feedbackCount, setFeedbackCount] = useState(0);
    const [avg3MonthReviewScore, setAvg3MonthReviewScore] = useState(0);
    const [feedbackOrReviews, setFeedbackOrReviews] = useState(true);
    const [newReviewsObject, setNewReviewsObject] = useState([]);

    const hotelId = localStorage.getItem('marie_hotel_id');

    const convertReviewsObject = () => {
        const newFeedbackObjects = reviews.map(review => {
            const {review: feedback, name, timestamp, ...rest} = review;
            const nameSplit = name.split(' ');
            const firstName = nameSplit[0];
            const lastName = nameSplit.slice(1).join(' ');
            const dateObject = new Date(timestamp);
            const date = dateObject.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            })
            return { ...rest, feedback, firstName, lastName, date };
        });
        setNewReviewsObject(newFeedbackObjects);
    }
    const getSideChatLockStatus = async() => {
        const url = "/api/reviews/getSideChatDisableFeatureStatus";
        try{
            const response = await axios.get(url, {
                params: {
                    hotelId
                },
            });
            const result = response?.data?.data;
            localStorage.setItem("review_response_disabled", result);
        }catch(err){
            console.error("Failed to get the side chat lock status", err);
        }
    };
    const extractNumerator = (rating) => {
        const regex = /^(\d+(\.\d+)?)\/(5|10)$/;
        const match = rating.match(regex);
        if (match) {
            let numerator = parseFloat(match[1]);
            const denominator = parseInt(match[3], 10);
            if (denominator === 10) {
                numerator = numerator / 2;
            }
            return numerator;
        }
        return 0;
    };
    const getGoogleReviews = async() => {
        try{
            axios.get(`/api/getReviews/${hotelId}/Reviews`)
                .then(response => {
                    setReviews(response.data);
                    // toggleFeedbackOrReviews(true);
                    getHotelReviewAvg();
                })
                .catch(error => {
                    console.error('Error fetching reviews:', error);
                });

        } catch(err){
            console.error("Error in getGoogleReviews: ",err)
        }
    }
    const getTypeforms = async() => {
        const TypeFormData = "TypeFormData";
        try{
            const response = await axios.get(`/api/typeform/getTypeforms/${hotelId}/${TypeFormData}`);
            const typeFormsData = response.data || {};
            const allFormsData = []
            const oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
            // Filter typeFormsData to include only items from the last month
            const recentTypeFormsData = typeFormsData.filter(typeForm => {
              const timestamp = new Date(typeForm.timestamp);
              return timestamp >= oneMonthAgo;
            });
            const threeAndUnderReviews = recentTypeFormsData.filter(typeForm => {
                return typeForm.questions[0]?.response < 4;
            })
            threeAndUnderReviews.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            threeAndUnderReviews.forEach(typeForm => {
                const timestamp = typeForm.timestamp;
                const date = new Date(timestamp);
                const readableFormat = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
                typeForm.timestamp = readableFormat;
                allFormsData.push(typeForm);
            });
            setTypeFormsData(allFormsData);
        } catch(err){
            console.error("Error fetching typeforms: ", err);
        }
    };

    const toggleFeedbackOrReviews = (isReviews) => {
        setFeedbackOrReviews(isReviews)
    };

    const filterReviewsWithDenominatorOf5 = async (reviews) => {
        const regex = /^(\d+(\.\d+)?)\/(5)$/;
        return reviews.filter(review => regex.test(review.rating));
    };

    const getHotelReviewAvg = async() => {
        const averageReviewScore = await axios.get(`api/getOverallReviewScore/${hotelId}`);
        if(averageReviewScore.data.OverallReviewScore){
            setAvgReviewScore(averageReviewScore.data.OverallReviewScore);

        }else{
            setAvgReviewScore(0);
        }

    };

    const filterReviewsWithinLast3Months = async (reviews) => {
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    
        return reviews.filter(review => {
            const reviewDate = new Date(review.timestamp);
            return reviewDate >= threeMonthsAgo;
        });
    };

    const getHotelReviewAvgForLast3Months = async () => {
        const reviewsWithDenominatorOf5 = await filterReviewsWithDenominatorOf5(reviews);
        const recentReviews = await filterReviewsWithinLast3Months(reviewsWithDenominatorOf5);
    
        if (recentReviews.length > 0) {
            let totalScore = 0;
            recentReviews.forEach(review => {
                const numerator = extractNumerator(review.rating);
                if (!isNaN(numerator)) {
                    totalScore += numerator;
                }
            });
            const avgScore = totalScore / recentReviews.length;
            setAvg3MonthReviewScore(avgScore.toFixed(1));
        } else {
            setAvg3MonthReviewScore(0);
        }
    };

    const getHotelFeedbackFormsCountForPastMonth = () => {
        try{
            const rightNow = new Date()
            const oneMonthAgo = new Date(new Date().setDate(rightNow.getDate() - 30));
            const oneMonthAgoFormatted = new Date(oneMonthAgo.toISOString().slice(0,10));
            const feedbackCount = typeFormsData.filter(form => {
                const formDate = new Date(form.timestamp);
                return formDate >= oneMonthAgoFormatted && formDate <= rightNow;
            }).length
            setFeedbackCount(feedbackCount);
        }catch(error){
            console.error('Error fetching feedback forms:', error);
            setFeedbackCount(0);
        }
    };
    
    useEffect(() => {
        getGoogleReviews();
        getTypeforms();
    },[]);
    useEffect(() => {
        getHotelFeedbackFormsCountForPastMonth();
    }, [typeFormsData])
    useEffect(() => {
        getHotelReviewAvg();
        getHotelReviewAvgForLast3Months();
        convertReviewsObject();
    }, [reviews]);
    useEffect(() => {
        getSideChatLockStatus();
    },[])
        return(
            <>
            <div className="reviews-panel-body">
                <div className="reviews-panel-content">
                    <div className='reviews-panel-header'>
                        <div className="reviews-panel-title">Reviews</div>
                    </div>
                    <div className="reviews-snapshot-container">
                        <Snapshot title={"Overall Score"} content={`${avgReviewScore} ★`} ></Snapshot>
                        <Snapshot title={"3 Month Avg"} content={`${avg3MonthReviewScore} ★`}></Snapshot>
                        <Snapshot title={"Feedback Forms"} content={`${feedbackCount}/month`}></Snapshot>
                    </div>
                    <div className="reviews-button-row">
                        <div onClick={() => toggleFeedbackOrReviews(true)} className={`reviews-menu-button ${feedbackOrReviews ? 'active' : ''}`}>Google Reviews</div>
                        <div onClick={() => toggleFeedbackOrReviews(false)} className={`feedback-menu-button ${feedbackOrReviews ? '' : 'active'}`}>Feedback Forms</div>
                    </div>
                    {feedbackOrReviews ? (
                        <>
                            <Table group={'reviews'} data={newReviewsObject}></Table>
                        </>
                    ) : (
                        <>
                            <Table group={'feedback'} data={typeFormsData}></Table>
                        </>
                    )}
                
                </div>
            </div>
        </>
    )
}

export default ReviewsPanel;