import React, { useState } from "react";
import "./style.scss";
import axios from "axios";
import CustomMessageForm from "../../Modal/CustomMessageForm/CustomMessageForm";
import ArrivalEntry from "../GuestListHeader/ArrivalEntry/ArrivalEntry";
import GuestUploadForm from "./GuestUploadForm/GuestUploadForm";


const GuestUpload = ({ onGuestAdd }) => {
	const handleFormDataSubmit = (data) => {
		onGuestAdd(data);
	}
    return (
		<div className="guest-upload-body">
			<GuestUploadForm onFormDataSubmit={handleFormDataSubmit} />
		</div>
    );
  };

export default GuestUpload;