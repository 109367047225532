import React, { useEffect, useState } from 'react';
import checkmarkIcon from '../../../assets/checkmark-icon.png';
import exclamationPointIcon from '../../../assets/exclamation-point-icon.png';
import gearIcon from '../../../assets/gear-icon.png';
import notificationSound from '../../../assets/notification-sound.mp3'
import toast from 'react-hot-toast';
import moment from 'moment-timezone';
import './style.scss';
import axios from 'axios';


class GuestRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      request: props.request,
      menuHidden: true,
      updateRequestStatus: props.updateRequestStatus,
      displayConversation: props.displayConversation,
      checked: (props.request.requestStatus === "COMPLETE"),
      categoryData: null,
      acronym: props.request?.filterDetails?.acronym,
      filterColor: props.request?.filterDetails?.color
    }
  }
  
  componentDidMount = () => {
    const alertTimestamp = this.state.request.creationTimestamp + (15 * 60 * 1000)
    const difference = alertTimestamp - Date.now()
    if (difference > 0) {
      setTimeout(this.alertToast, difference)
    }
  };

  determineStatusImage = () => {
    if (this.state.request.requestStatus === 'COMPLETE') {
      return checkmarkIcon
    } else if (this.state.request.requestStatus === 'INCOMPLETE') {
      return exclamationPointIcon
    } else {
      return gearIcon
    }
  }

  determineStatusImageCssClass = () => {
    if (this.state.checked === true) {
      return 'complete'
    } else {
      return 'incomplete'
    } 
  }
  
  onIconClick = () => {
    this.setState({
      menuHidden: !this.state.menuHidden
    })
  }
  
  getRequestText = () => {
    if (this.state.request.text.length > 140) {
      return this.state.request.text.substring(0,140) + "..."
    } else {
      return this.state.request.text
    }
  }

  getReadableTime = () => {
    const creationMoment = moment.unix(this.state.request.creationTimestamp/1000).utc().tz('America/Denver')
    const formattedTime = creationMoment.format('h:mm A')
    return formattedTime
  }
  
  messageGuest = () => {
    window.location = `/inbox?guestPhoneNumber=${this.state.request.guestPhoneNumber}`
  }

  alertToast = () => {
    new Audio(notificationSound).play()
    toast((t) => (
      <span onClick={() => toast.dismiss(t.id)}>
        {`${this.state.request.guestName}'s request needs your attention!`}
      </span>
    ), {
      position: 'top-right',
      icon: '⚠️',
      duration: 5000
    });
  }

  updateRequestStatus = (status) => {
    // this.setState({
      //   menuHidden: true
      // })
      this.state.updateRequestStatus(this.state.request, status)
      this.setState({
        checked: status === "COMPLETE"
      })
    }
    
  render() {
    return (
      <div className="guest-request-centering-container">
        <div className="guest-request-container">
          {/* <div className={`status-menu ${this.state.menuHidden ? 'hidden' : ''}`}>
            <div className="menu-row-container divider-line" onClick={() => this.updateRequestStatus('INCOMPLETE')}>
            <div className="status-icon-menu-background incomplete">
                <img src={exclamationPointIcon} className="status-icon-menu-image incomplete"></img>
                </div>
                <span className="status-menu-text">Not complete</span>
                </div>
                <div className="menu-row-container divider-line" onClick={() => this.updateRequestStatus('IN_PROGRESS')}>
              <div className="status-icon-menu-background in-progress">
              </div>
              <span className="status-menu-text">In progress</span>
              </div> 
              <div className="menu-row-container" onClick={() => this.updateRequestStatus('COMPLETE')}>
              <div className="status-icon-menu-background complete">
              </div>
              <span className="status-menu-text">Complete</span>
              </div>
            </div> */}
          <div className='requests-checkbox-ctn'>
            <div className="check-box-container" popuptext={`Mark as ${(this.state.checked !== true) ? 'Closed' : 'Open' }`}>
              <input type="checkbox" checked={this.state.checked} onChange={(e) => {e.stopPropagation && (this.state.checked === true) ? this.updateRequestStatus('INCOMPLETE') : this.updateRequestStatus('COMPLETE')}}/>
            </div>
          </div>
          <div className="guest-request-container" onClick={(e) => {
            e.stopPropagation &&
            this.state.displayConversation(this.state.request.id, this.state.request.guestName, this.state.request.guestPhoneNumber, this.state.request.creationTimestamp);
          }}>
            <div className="guest-request-name-container">
              <div className="guest-request-name">{this.state.request.guestName.replace('null','')}</div>
            </div>
            <div className='guest-request-category-container' style={{backgroundColor: this.state.filterColor}}>
              <div className='guest-request-category'>{this.state.acronym}</div>
            </div>
            <div className="guest-request-text-container">
              <div>"{this.getRequestText()}"</div>
            </div>
            <div className="request-time-container">
              <div className="request-time">{this.getReadableTime()}</div>
            </div>
            <div className="guest-request-message-guest-button-container">
              <div className={`status-icon-container ${this.determineStatusImageCssClass()}`} onClick={this.onIconClick}>
                <div className="status-icon-text">{(this.state.checked) ? 'Closed' : 'Open'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default GuestRequest;

// const GuestRequest = ({request, updateRequestStatus, displayConversation, checked}) => {
//   const [menuHidden, setMenuHidden] = useState(true);
//   const [isChecked, setIsChecked] = useState(checked);
  
//   const onIconClick = () => {
//     setMenuHidden(!menuHidden);
//   }
  
//   const determineStatusImageCssClass = () => {
//     if (checked === true) {
//       return 'COMPLETE'
//     } else {
//       return 'INCOMPLETE'
//     } 
//   }
  
//   const getRequestText = () => {
//     if (request.text.length > 140) {
//       return request.text.substring(0,140) + "..."
//     } else {
//       return request.text
//     }
//   }

//   const getRequestCategory = () => {
//     switch(request.requestCategory){
//       case 'Housekeeping':
//         return (<div className='guest-request-category' style={{backgroundColor: '#A53B3B'}}>HK</div>);
//       case 'FrontDesk':
//         return (<div className='guest-request-category' style={{backgroundColor: '#ff9500'}}>FD</div>);
//       case 'Maintenance':
//         return (<div className='guest-request-category' style={{backgroundColor: '#BF8A22'}}>M</div>);
//       case 'Restaurant':
//         return (<div className='guest-request-category' style={{backgroundColor: '#472088'}}>R</div>);
//       default:
//         return (<div style={{backgroundColor: 'transparent' }} className='guest-request-category'></div>);
//     }
//   }

//   const getReadableTime = () => {
//     const creationMoment = moment.unix(request.creationTimestamp/1000).utc().tz('America/Denver')
//     const formattedTime = creationMoment.format('h:mm A')
//     return formattedTime
//   }

//   const updateRequest = (status) => {
//     updateRequestStatus(request, status)
//     setIsChecked(status === "COMPLETE");
//   }

//   const alertToast = () => {
//     new Audio(notificationSound).play()
//     toast((t) => (
//       <span onClick={() => toast.dismiss(t.id)}>
//         {`${request.guestName}'s request needs your attention!`}
//       </span>
//     ), {
//       position: 'top-right',
//       icon: '⚠️',
//       duration: 5000
//     });
//   }

//   useEffect(() => {
//     const alertTimestamp = request.creationTimestamp + (15 * 60 * 1000);
//     const difference = alertTimestamp - Date.now();
//     if(difference > 0){
//       setTimeout(alertToast, difference)
//     }
//   })

//     return (
//       <div className="guest-request-centering-container">
//         <div className="guest-request-container">
//           {/* <div className={`status-menu ${this.state.menuHidden ? 'hidden' : ''}`}>
//             <div className="menu-row-container divider-line" onClick={() => this.updateRequestStatus('INCOMPLETE')}>
//               <div className="status-icon-menu-background incomplete">
//                 <img src={exclamationPointIcon} className="status-icon-menu-image incomplete"></img>
//               </div>
//               <span className="status-menu-text">Not complete</span>
//             </div>
//              <div className="menu-row-container divider-line" onClick={() => this.updateRequestStatus('IN_PROGRESS')}>
//               <div className="status-icon-menu-background in-progress">
//               </div>
//               <span className="status-menu-text">In progress</span>
//             </div> 
//             <div className="menu-row-container" onClick={() => this.updateRequestStatus('COMPLETE')}>
//               <div className="status-icon-menu-background complete">
//               </div>
//               <span className="status-menu-text">Complete</span>
//             </div>
//           </div> */}
//           <div className="check-box-container" popuptext={`Mark as ${(isChecked !== true) ? 'Closed' : 'Open' }`}>
//             <input type="checkbox" checked={isChecked} onChange={(e) => {e.stopPropagation && (isChecked === true) ? updateRequest('INCOMPLETE') : updateRequest('COMPLETE')}}/>
//           </div>
//           <div className="guest-request-container" onClick={(e) => {
//             e.stopPropagation &&
//             displayConversation(request.id, request.guestName, request.guestPhoneNumber, request.creationTimestamp);
//             }}>
//             <div className="guest-request-name-container">
//               <div className="guest-request-name">{request.guestName.replace('null','')}</div>
//             </div>
//             <div className='guest-request-category-container'>
//               <div>{getRequestCategory()}</div>
//             </div>
//             <div className="guest-request-text-container">
//               <div>"{getRequestText()}"</div>
//             </div>
//             <div className="request-time-container">
//               <div className="request-time">{getReadableTime}</div>
//             </div>
//             <div className="guest-request-message-guest-button-container">
//               <div className={`status-icon-container ${determineStatusImageCssClass}`} onClick={onIconClick}>
//                 <div className="status-icon-text">{(isChecked) ? 'Closed' : 'Open'}</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
// }