import React from "react";
import MessagePreview from "./MessagePreview/MessagePreview";
import "./style.scss";
import loadingSpinner from "../../../assets/eclipse-1s-200px.gif";

class Inbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			displayConversation: props.displayConversation,
			conversationPreviews: props.conversationPreviews,
			conversationsLoaded: props.conversationsLoaded,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({
				conversationPreviews: this.props.conversationPreviews,
				conversationsLoaded: this.props.conversationsLoaded,
        messages: this.props.messages
			});
		}
	}

  render() {
    return (
      <div className="inbox-body">
        <div className="inbox-content-body">
          { !this.state.conversationsLoaded ? 
            <div className="loading-container">
              <img className="loading-spinner" src={loadingSpinner}></img>
            </div>
          : this.state.conversationPreviews
          .map(conversationPreview =>
            <MessagePreview
              key={conversationPreview.id}
              lastMessages={this.state.messages.filter(message => message.conversation.sid === conversationPreview.id)}
              id={conversationPreview.id}
              name={conversationPreview.name}
              phoneNumber={conversationPreview.phoneNumber}
              messageBody={conversationPreview.messageBody}
              timestamp={conversationPreview.timestamp}
              unread={conversationPreview.unread}
              unixTimestamp={conversationPreview.unixTimestamp}
              displayConversation={this.state.displayConversation}
            >
            </MessagePreview>
          )}
        </div>
      </div>
    );
  }
}

export default Inbox;
