import React, { useState } from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import InboxPanel from '../../components/InboxPanel/InboxPanel';

function InboxPage({ chatToken, hotelId }) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="dashboard-body">
      <ControlPanel page="inbox"></ControlPanel>
      <InboxPanel chatToken={chatToken} hotelId={hotelId}></InboxPanel>
    </div>
  );
}

export default InboxPage;
