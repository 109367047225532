import React from "react";
// import SearchBar from "./SearchBar/SearchBar";
import PaginationFooter from "../PaginationFooter/PaginationFooter";
import Inbox from "./Inbox/Inbox";
import backButton from "../../assets/back-button.svg";
import "./style.scss";
import Conversation from "./Conversation/Conversation";
import { Client as ConversationsClient } from "@twilio/conversations";
import axios from "axios";
import SideChat from "../SideChat/SideChat";

const LIST_SIZE = 12;

class InboxPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			conversations: [],
			conversationPreviews: [],
			conversationsLoaded: false,
			currentConversation: null,
			currentGuestPhoneNumber: null,
			manualConversationIds: [],
			manualTimeouts: [],
			conversationName: "",
			token: props.chatToken,
			hotelId: props.hotelId,
			showBlockOverlay: false,
			currentPage: 1,
			totalMessages: 0,
			messages: [],
			isSideChatOpen: false,
			sideChatData: null,
			isBlocked: false,
			arrivalData: props.arrivalData
		};
	}

	componentDidMount = async () => {
		await this.initConversations();

		const searchParams = new URLSearchParams(window.location.search);
		if(searchParams.has('guestPhoneNumber')){
			searchParams.delete('guestPhoneNumber');
			const newRelativePathQuery = window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : '');
			window.history.replaceState(null, '', newRelativePathQuery)
		};
		// After initializing the conversations, check if there's a currentGuestPhoneNumber in state
		const { currentGuestPhoneNumber } = this.state;
		
		if (currentGuestPhoneNumber) {
			await this.getBlockedStatus(currentGuestPhoneNumber);
		}

		// Assume inbox is empty if we haven't grabbed a conversation in 7 seconds
		setTimeout(() => {
			this.setState({
				conversationsLoaded: true,
			});
		}, 7000);
	};

	getUniqueConversationPreviews = (conversationPreviews) => {
		const seen = {};

		return conversationPreviews.filter(function (item) {
			return seen.hasOwnProperty(item.phoneNumber) ? false : (seen[item.phoneNumber] = true);
		});
	}

	receivedMessagePaginator = async (messagePaginator, conversation) => {
		this.setState((prevState) => ({
			totalMessages: prevState.totalMessages + messagePaginator.items.length,
			messages: [...prevState.messages, ...messagePaginator.items],
		}));

		if (messagePaginator.hasNextPage) {
			messagePaginator.nextPage().then(this.receivedMessagePaginator);
		} else {
			const conversationPreview = this.createConversationPreview(conversation, this.state.messages);
			const response = await this.fetchManualStatus(conversationPreview.phoneNumber);
			if (response.isManualMode) {
				this.addToManualConversations(conversationPreview.id, response.manualEndsTimestamp);
			}

			this.setState((prevState) => {
				return {
					conversationsLoaded: true,
					conversationPreviews: [...prevState.conversationPreviews, conversationPreview].sort(this.sortConversationsByTimestamp),
				};
			});
		}
	};

	initConversations = async () => {
		window.conversationsClient = ConversationsClient;
		this.conversationsClient = new ConversationsClient(this.state.token);

		this.conversationsClient.on("conversationJoined", (conversation) => {
			this.setState((prevState, props) => {
				return { conversations: [...prevState.conversations, conversation] };
			}, this.checkForSpecifiedConversation);
			conversation
				.getMessages()
				.then(async (messagePaginator) => {
					this.receivedMessagePaginator(messagePaginator, conversation);
					const conversationPreview = this.createConversationPreview(conversation, messagePaginator.items);
					const response = await this.fetchManualStatus(conversationPreview.phoneNumber);
					if(response.isManualMode){
						this.addToManualConversations(conversationPreview.id, response.manualEndsTimestamp)
					}
					this.setState((prevState) => {
						return{
							conversationsLoaded: true,
							conversationPreviews: [...prevState.conversationPreviews, conversationPreview].sort((a, b) => b.unixTimestamp - a.unixTimestamp),
						};
					});
				})
				.catch((err) => {
					console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
				});
			conversation.on("messageAdded", (message) => {
				this.removeFromReadConversationsInLocalStorage(conversation.sid);
				this.updateConversationPreview(conversation.sid, message);
			});
		});
	};

	formatDateToCustomString = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
	}

	checkForSpecifiedConversation = () => {
		var searchParams = new URLSearchParams(window.location.search);
		const guestPhoneNumber = searchParams.get("guestPhoneNumber");
		if (guestPhoneNumber) {
			const conversation = this.getConversationFromPhoneNumber(guestPhoneNumber);
			if (conversation) {
				const id = conversation.sid;
				const name = conversation.attributes?.guestName ?? "Guest";
				this.displayConversation(id, name, guestPhoneNumber, null);
			}
		}
	};

	sortConversationsByTimestamp = (a, b) => {
		try {
			const conversationA = this.state.messages.filter(message => message.conversation.sid === a.id);
			const conversationB = this.state.messages.filter(message => message.conversation.sid === b.id);

			const conversationALastMessage = conversationA[conversationA.length - 1];
			const conversationBLastMessage = conversationB[conversationB.length - 1];

			return conversationBLastMessage.state.timestamp - conversationALastMessage.state.timestamp;
		} catch (error) {
			console.error("unable to sort guest", error);
			return false;
		}

	}

	updateConversationPreview(conversationId, message, unixTimestamp) {
		let conversationPreviews = this.state.conversationPreviews;
		const index = conversationPreviews.findIndex((preview) => {
			return preview.id === conversationId;
		});
		if (index >= 0) {
			if (message) {
				if (message.state.type === "media") {
					conversationPreviews[index].messageBody = "Attachment: 1 Image";
				} else {
					conversationPreviews[index].messageBody = '"' + message.state.body + '"';
				}
			}
			conversationPreviews[index].unread = !this.getConversationReadStatusFromLocalStorage(conversationId);

			if (unixTimestamp) {
				const options = {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
					month: "short",
					day: "numeric",
					year: "numeric",
				};
				conversationPreviews[index].unixTimestamp = unixTimestamp;
				conversationPreviews[index].timestamp = unixTimestamp?.toLocaleString("en-US", options);
				conversationPreviews.sort(this.sortConversationsByTimestamp);
			}

			this.setState({
				conversationPreviews: conversationPreviews,
			});
		}
	}

	fetchManualStatus = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("/api/chat/manual-status", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: this.state.hotelId,
			});
			return response.data;
		} catch (error) {
			console.error("unable to retreive manual status", error);
			return false;
		}
	};

	adjustManualTimeouts = (conversationId, shouldAdd, timestamp) => {
		if (shouldAdd) {
			const now = new Date().getTime();
			const difference = timestamp - now;
			const timeoutId = setTimeout(() => {
				this.removeFromManualConversations(conversationId);
			}, difference);
			this.setState((prevState) => {
				return {
					manualTimeouts: [
						...prevState.manualTimeouts,
						{
							conversationId: conversationId,
							timeoutId: timeoutId,
						},
					],
				};
			}, this.logState);
		} else {
			const pairing = this.state.manualTimeouts.find((pairing) => pairing.conversationId === conversationId);
			if (pairing) {
				this.setState(
					(prevState) => ({
						manualConversationIds: prevState.manualTimeouts.filter((pairing) => pairing.conversationId !== conversationId),
					}),
					this.logState
				);
				clearTimeout(pairing.timeoutId);
			}
		}
	};

	updateManualTimeoutEntryInDatabase = async (hotelId, guestPhonenumber, timestamp) => {
		const response = await axios
			.post("/api/chat/set-to-manual", {
				hotelId: hotelId,
				guestPhoneNumber: guestPhonenumber,
				timestamp: timestamp.toString(),
			});
	};

	addToManualConversations = (conversationId, timestamp) => {
		this.setState((prevState) => {
			return { manualConversationIds: [...prevState.manualConversationIds, conversationId] };
		}, this.logState);
		this.adjustManualTimeouts(conversationId, true, timestamp);
	};

	removeFromManualConversations = (conversationId) => {
		this.setState(
			(prevState) => ({
				manualConversationIds: prevState.manualConversationIds.filter((id) => id !== conversationId),
			}),
			this.logState
		);
		this.adjustManualTimeouts(conversationId, false, null);
	};

	toggleManualSwitch = (event) => {
		const shouldAdd = event.target.checked;
		if (shouldAdd) {
			const timestamp = new Date().getTime() + 1 * 60 * 1000 * 15; // TODO: make configurable
			this.addToManualConversations(this.state.currentConversation.sid, timestamp);
			this.updateManualTimeoutEntryInDatabase(this.state.hotelId, this.state.currentGuestPhoneNumber, timestamp);
		} else {
			this.removeFromManualConversations(this.state.currentConversation.sid);
			const now = new Date().getTime();
			this.updateManualTimeoutEntryInDatabase(this.state.hotelId, this.state.currentGuestPhoneNumber, now);
		}
	};

	isInManualMode = () => {
		return this.state.manualConversationIds.includes(this.state.currentConversation.sid);
	};

	logState = () => { };

	createConversationPreview = (conversation, messages) => {
		let conversationPreview = {};
		conversationPreview.id = conversation.sid;
		conversationPreview.name = conversation.attributes?.guestName ?? "Guest";
		conversationPreview.name = conversationPreview.name.replace("null", ""); // Old error results in null appended at the end
		conversationPreview.phoneNumber = conversation.attributes?.guestPhoneNumber ?? "";
		conversation.unread = !this.getConversationReadStatusFromLocalStorage(conversation.sid);
		const lastMessage = messages[messages.length - 1];
		if (lastMessage?.state?.type === "media") {
			conversationPreview.messageBody = "Attachment: 1 Image";
		} else {
			conversationPreview.messageBody = '"' + lastMessage?.state?.body + '"';
		}
		const options = {
			hour: "numeric",
			minute: "numeric",
			hour12: true,
			month: "short",
			day: "numeric",
			year: "numeric",
		};
		const latestTimestamp = lastMessage?.state?.timestamp;
		conversationPreview.unixTimestamp = latestTimestamp;
		conversationPreview.timestamp = latestTimestamp?.toLocaleString("en-US", options);
		return conversationPreview;
	};

	// TODO: read and unread colors
	getConversation = (id) => {
		return this.state.conversations.find((conversation) => conversation.sid === id);
	};

	getConversationFromPhoneNumber = (guestPhoneNumber) => {
		try {
			
			guestPhoneNumber = guestPhoneNumber.replace(/\s/g, "");

			const guestConversation = this.state.conversations.find((conversation) => {
				return (
					conversation.attributes && conversation.attributes.guestPhoneNumber && conversation.attributes.guestPhoneNumber.includes(guestPhoneNumber)
				);
			});
			return guestConversation;
		} catch (error) {
			console.error(`Error encountered in getConversationFromPhoneNumber: ${error}`);
		}
	};

	setSearchParams = (key, value) => {
		var searchParams = new URLSearchParams(window.location.search);
		searchParams.set(key, value);
		var newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
		window.history.pushState(null, "", newRelativePathQuery);
	};
	closeSideChat = () => {
		this.setState({
			isSideChatOpen: false,
			sideChatData: null,
		});
	};

	displayConversation = async (id, name, phoneNumber, unixTimestamp) => {
		const currentConversation = this.getConversation(id);
		if(currentConversation){
			this.setSearchParams("guestPhoneNumber", currentConversation.attributes.guestPhoneNumber);
			this.addToReadConversationsInLocalStorage(id);
			this.updateConversationPreview(id, null, unixTimestamp);
			const allConversationContent = {
				...currentConversation,
				guestName: name,
				guestPhoneNumber: phoneNumber,
				id: id,
			};
			this.setState(
				{
					currentConversation: currentConversation,
					conversationName: name,
					currentGuestPhoneNumber: phoneNumber,
					sideChatData: allConversationContent,
					isSideChatOpen: true,
				},
				async () => {
					const isBlocked = await this.getBlockedStatus(phoneNumber);
					this.setState({ isBlocked });
				}
			);
			
		}
	};

	getConversationReadStatusFromLocalStorage(conversationId) {
		let readConversations = JSON.parse(localStorage.getItem("marie-read-conversations"));
		if (!readConversations) {
			return false;
		} else {
			return readConversations.includes(conversationId);
		}
	}

	addToReadConversationsInLocalStorage(conversationId) {
		let readConversations = JSON.parse(localStorage.getItem("marie-read-conversations"));
		if (!readConversations) {
			readConversations = [];
		}
		readConversations.push(conversationId);
		localStorage.setItem("marie-read-conversations", JSON.stringify(readConversations));
	}

	removeFromReadConversationsInLocalStorage(conversationId) {
		let readConversations = JSON.parse(localStorage.getItem("marie-read-conversations"));
		if (!readConversations) {
			readConversations = [];
		}
		let index = readConversations.indexOf(conversationId);
		readConversations.splice(index, 1);
		localStorage.setItem("marie-read-conversations", JSON.stringify(readConversations));
	}

	onBackButtonClicked = () => {
		this.setState({ currentConversation: null });
		window.history.pushState(null, "", window.location.pathname);
	};

	handleBlockUnblockClick = (event) => {
		event.preventDefault();
		const guestPhoneNumber = this.state.currentGuestPhoneNumber;
		if (guestPhoneNumber) {
			if (this.state.isBlocked) {
				return this.unblockUser(guestPhoneNumber);
			} else {
				return this.blockUser(guestPhoneNumber);
			}
		}
	};

	blockUser = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("api/chat/block", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: this.state.hotelId,
			});
			if (response.status === 200) {
				this.setState({ isBlocked: true });
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	unblockUser = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("api/chat/unblock", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: this.state.hotelId,
			});
			if (response.status === 200) {
				this.setState({ isBlocked: false });
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	getBlockedStatus = async (guestPhoneNumber) => {
		try {
			const response = await axios.get("api/chat/blocked-status", {
				params: {
					guestPhoneNumber: guestPhoneNumber,
					hotelId: this.state.hotelId,
				},
			});
			if (response.status === 200) {
				return response.data.blocked;
			} else {
				return false;
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	// method to toggle the block overlay
	toggleBlockOverlay = () => {
		this.setState((prevState) => ({ showBlockOverlay: !prevState.showBlockOverlay }));
	};

	setCurrentPage = (page) => {
		this.setState({ currentPage: page });
	};

	render() {
		return (
			<div className="inbox-panel-body">
				<div className="inbox-panel-content">
					<div className="inbox-panel-header">
						<div>
							<div className="inbox-panel-title">Guest Conversations</div>
									{/* <div className="manual-switch-container">
										<span className="manual-switch-label">Manual Mode</span>
										<div className="just-switch-container">
											<label className="switch">
												<input type="checkbox" onChange={this.toggleManualSwitch} checked={this.isInManualMode()} />
												<span className="slider round"></span>
											</label>
										</div>
										<div className="block-switch-container">
											<div className="block-switch-label" onClick={this.toggleBlockOverlay}>
												<span className="block-switch-label-text">&#xFE19;</span>
											</div>
											{this.state.showBlockOverlay && (
												<div className="block-overlay">
													<span className="block-overlay-text" onClick={this.handleBlockUnblockClick}>
														{this.state.isBlocked ? "Unblock User" : "Block User"}
													</span>
												</div>
											)}
										</div>
									</div> */}
						</div>
						{/* {this.state.currentConversation == null && <SearchBar></SearchBar>} */}
					</div>
					{this.state.currentConversation == null}
					<div id="inbox-conversation-container" className={(this.state.currentConversation == null) ? "inbox-container" : "conversation-container"}>
						{/* {this.state.currentConversation == null ? ( */}
							<div>
								<Inbox
									conversationPreviews={
										this.getUniqueConversationPreviews(this.state.conversationPreviews)
											.slice((this.state.currentPage - 1) * LIST_SIZE, this.state.currentPage * LIST_SIZE)
									}
									conversationsLoaded={this.state.conversationsLoaded}
									displayConversation={this.displayConversation}
									messages={this.state.messages}
								></Inbox>

								{
									this.state.conversationPreviews.length ?
										<PaginationFooter
											currentPage={this.state.currentPage}
											setCurrentPage={this.setCurrentPage}
											numberOfTotalPreviews={this.getUniqueConversationPreviews(this.state.conversationPreviews).length}
											numberOfPreviews={this.state.currentPage}
											pageSize={12}
										/>
										: null
								}
							</ div>
						{/* ) : ( */}
							{/* <Conversation
							conversation={this.state.currentConversation}
							hotelId={this.state.hotelId}
							guestPhoneNumber={this.state.currentGuestPhoneNumber}
							addToManualConversations={this.addToManualConversations}
							removeFromManualConversations={this.removeFromManualConversations}
							></Conversation> */}
						{/* )} */}
						{this.state.isSideChatOpen && (
								<SideChat
									data={this.state.sideChatData}
									arrivalData={this.props.arrivalData}
									hotelId={this.state.hotelId}
									conversation={this.state.currentConversation}
									guestPhoneNumber={this.state.currentGuestPhoneNumber}
									constructor={"conversations"}
									isOpen={this.state.isSideChatOpen}
									toggleSideChat={this.closeSideChat}
									addToManualConversations={this.addToManualConversations}
									removeFromManualConversations={this.removeFromManualConversations}
									toggleManualSwitch={this.toggleManualSwitch}
									isInManualMode={this.isInManualMode}
									toggleBlockOverlay={this.toggleBlockOverlay}
									handleBlockUnblockClick={this.handleBlockUnblockClick}
									showBlockOverlay={this.state.showBlockOverlay}
									onBackButtonClicked={this.onBackButtonClicked}
									isBlocked={this.state.isBlocked}
								/>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default InboxPanel;
