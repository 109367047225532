import React, { useState, useEffect } from 'react';
import './style.scss';
import axios from 'axios';
import Table from '../Table/Table';

const LeadsPanel = () => {
    const [formData, setFormData] = useState([]);
    const [formObjects, setformObjects] = useState({});
    const [group, setGroup] = useState('');
    const hotelId = localStorage.getItem('marie_hotel_id');

    const getLeadForm = async (leadForm) => {
        try {
            const response = await axios.get(`/api/typeform/getTypeForms/${hotelId}/${leadForm}`);
            const allFormsData = response.data.map(form => {
                return {
                    id: form.id,
                    issued: form.issued,
                    key: form.key,
                    timestamp: form.timestamp,
                    ...form
                };
            });
            setFormData(allFormsData)
            
        } catch (err) {
            console.error("Error fetching typeforms: ", err);
        }
    };

    const getFormType = async () => {
        try{
            const response = await axios.get(`/api/typeform/getFormType/${hotelId}/LeadForms`);
            const allFormTypes = Object.entries(response.data);
            setformObjects(response.data);
            if(allFormTypes.length > 0){
                setGroup(allFormTypes[0][0]);
            }
        }catch(err){
            console.error("Error in getFormType React: ", err);
        }
    }
    useEffect(() => {
        getFormType();
    }, [])
    useEffect(() => {
        if(group){
            getLeadForm(group);
        }
    }, [group]);

    const filteredFormData = formData.filter(form => form.key === group);

    return (
        <div className="leads-panel-body">
            <div className="leads-panel-content">
                <div className='leads-panel-header'>
                    <div className="leads-panel-title">Leads</div>
                </div>
                <div className='leads-button-row'>
                    <div className='form-tab-ctn' >
                        {Object.entries(formObjects).map(([key, value], index) => (
                            <>
                                <div key={key} className={`form-tab ${group === key ? 'selected' : ''}`} onClick={() => setGroup(key)}>
                                    {value}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                {formData.length > 0 ? (
                    <>
                        <div className='leads-label-row'>
                            <div id='over-checkbox'></div>
                            <div className='leads-label' id='over-name'>Name</div>
                            <div id='over-content'></div>
                            <div className='leads-label' id='over-date'>Date</div>
                            <div className='leads-label' id='over-status'>Status</div>
                        </div>
                        <Table group={'leads'} data={filteredFormData}/>
                    </>
                ) : (
                    <>
                        <h1 id='no-leads' style={{}}>No leads at the moment</h1>
                    </>
                )}
            </div>
        </div>
    );
}

export default LeadsPanel;