import React, { useState } from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import InboxPanel from '../../components/InboxPanel/InboxPanel';
import ArrivalsPanel from '../../components/ArrivalsPanel/ArrivalsPanel';
import ReviewsPanel from '../../components/ReviewsPanel/ReviewsPanel';

function ReviewsPage({ chatToken, hotelId }) {
    return (
      <div className="arrivals-body">
        <ControlPanel page="reviews"></ControlPanel>
        <ReviewsPanel></ReviewsPanel>
      </div>
    )
  }

export default ReviewsPage;