import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import chevronDown from '../../../assets/chevron-down.svg';
import cross from '../../../assets/cross.png';
import axios from 'axios';

function RequestsFilter({ onChange }) {
  const [activeFilters, setActiveFilters] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const hotelId = localStorage.getItem('marie_hotel_id');
  const [filterOptions, setFilterOptions] = useState({'INCOMPLETE': "Open", 'COMPLETE': "Closed"});

  const handleFilterChange = (filter) => {
    let updatedFilters;
    if (filter === "Select All") {
      if (activeFilters.includes("Select All") || activeFilters.length === Object.keys().length - 1) {
        updatedFilters = [];
      } else {
        updatedFilters = Object.keys(filterOptions);
      }
    } else {
      if (activeFilters.includes(filter)) {
        updatedFilters = activeFilters.filter(f => f !== filter);
        const index = updatedFilters.indexOf("Select All");
        if (index > -1) {
          updatedFilters.splice(index, 1);
        }
      } else {
        updatedFilters = [...activeFilters, filter];
      }
      if (updatedFilters.length === Object.keys(filterOptions).length - 1) {
        updatedFilters.push("Select All");
      } else if (updatedFilters.includes("Select All")) {
        updatedFilters = updatedFilters.filter(f => f !== "Select All");
      }
    }

    if (filter === "Open") {
      updatedFilters = [...updatedFilters.filter(f => f !== "Closed"), "INCOMPLETE"];
    } else if (filter === "Closed") {
      updatedFilters = [...updatedFilters.filter(f => f !== "Open"), "COMPLETE"];
    }
    setActiveFilters(updatedFilters);
    onChange(updatedFilters);
  };
  const getFilters = async () => {
    try {
      const response = await axios.get(`/api/requests/request-filters/${hotelId}`);
      const fetchedOptions = response.data;
      const updatedOptions = { ...filterOptions, ...fetchedOptions };
      setFilterOptions(updatedOptions);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  const clearAllFilters = () => {
    setActiveFilters([]);
    onChange([]);
  };

  useEffect(() => {
    getFilters();
  }, [])

  return (
    <>
      <div className="filters-container">
        <div className='filters-ctn'>
          <div className="filter-selector" onClick={() => setShowDropdown(!showDropdown)}>
            <div id='filter-label'>
              Filters
            </div>
            <div id='arrow-label'><img src={chevronDown}></img></div>
          </div>
          {showDropdown && (
            <ul className="filter-dropdown-menu" ref={dropdownRef}>
              <div className='cancel-filter'><img className='cancel-filter-image' src={cross} onClick={() => setShowDropdown(false)}></img></div>
              {Object.entries(filterOptions).map(([option, label]) => (
                <li key={option} className='filter-item'>
                  <label className='filter-item-label'>
                    <input
                      className='filter-checkbox'
                      type="checkbox"
                      checked={activeFilters.includes(option) || (option === "Open" && activeFilters.includes("INCOMPLETE")) || (option === "Closed" && activeFilters.includes("COMPLETE"))}
                      onChange={() => handleFilterChange(option)}
                    />
                    {label}
                  </label>
                </li>
              ))}
            </ul>
          )}
          <div className="selected-filters">
            {activeFilters.map(filter => (
              filter === "Select All" ? null : (
                <>
                  <div key={filter} className="selected-filter-ctn">
                    <div className='selected-filter-cell'>
                      <div className='selected-filter-label'>{filterOptions[filter]}</div>
                      <div className='selected-filter'>
                        <img className='cancel-filter-image' src={cross} alt="Remove Filter" onClick={() => handleFilterChange(filter)} />
                      </div>
                    </div>
                  </div>
                </>
              )
            ))}
            {activeFilters.length > 0 && (
              <div className='clear-filters-btn-ctn'>
                <div className="clear-filters-btn" onClick={clearAllFilters}>
                  Clear Filters
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestsFilter;