import React, { useState } from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import InboxPanel from '../../components/InboxPanel/InboxPanel';
import ArrivalsPanel from '../../components/ArrivalsPanel/ArrivalsPanel';

function ArrivalsPage({ chatToken, hotelId }) {
    return (
      <div className="arrivals-body">
        <ControlPanel page="arrivals"></ControlPanel>
        <ArrivalsPanel></ArrivalsPanel>
      </div>
    )
  }

export default ArrivalsPage;