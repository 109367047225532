import React from 'react';
import RequestsFilter from './RequestsFilter/RequestsFilter';
import PaginationFooter from '../PaginationFooter/PaginationFooter';
import GuestRequest from './GuestRequest/GuestRequest';
import axios from 'axios';
import toast from 'react-hot-toast';
import './style.scss';
import '../InboxPanel/style.scss';
import customMessageButton from "../../assets/custom-message-button.png";
import customMessageIcon from "../../assets/custom-message-icon.png";
import blastMessageIcon from "../../assets/blast-message-icon.png";
import Modal from '../Modal/Modal';
import CustomMessageForm from '../Modal/CustomMessageForm/CustomMessageForm';
import SideChat from '../SideChat/SideChat';
import { Client as ConversationsClient } from "@twilio/conversations";
import BlastMessageForm from '../Modal/BlastMessageForm/BlastMessageForm'

const LIST_SIZE = 12;

class RequestsPanel extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			requests: [],
			filteredRequests: [],
			activeFilters: [],
			activeStatusFilters: [],
			statusFilter: 'ALL',
			isSideChatOpen: false,
			conversations: [],
			conversationPreviews: [],
			conversationsLoaded: false,
			currentConversation: null,
			currentGuestPhoneNumber: null,
			manualConversationIds: [],
			manualTimeouts: [],
			conversationName: "",
			token: props.chatToken,
			hotelId: props.hotelId,
			showBlockOverlay: false,
			showModal: false,
			currentPage: 1,
			messages: [],
			sideChatData: null,
			isManualMode: false,
			isBlocked: false,
			messageType: '',
			arrivalData: props.arrivalData,
			openOptions: false,
		}
	}

	handleOpenModal = (messageType) => {
		this.setState({
			showModal: true,
			messageType: messageType
		})
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false
		})
	}

	componentDidMount = async () => {
		this.fetchRequests()
		setInterval(this.fetchRequests, 15 * 1000)

		await this.initConversations();

		const searchParams = new URLSearchParams(window.location.search);
		if(searchParams.has('guestPhoneNumber')){
			searchParams.delete('guestPhoneNumber');
			const newRelativePathQuery = window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : '');
			window.history.replaceState(null, '', newRelativePathQuery)
		};
		// After initializing the conversations, check if there's a currentGuestPhoneNumber in state
		const { currentGuestPhoneNumber } = this.state;
		if (currentGuestPhoneNumber) {
			await this.getBlockedStatus(currentGuestPhoneNumber);
		}

		// Assume inbox is empty if we haven't grabbed a conversation in 7 seconds
		setTimeout(() => {
			this.setState({
				conversationsLoaded: true,
			});
		}, 7000);
	};

	//********************************************************************************************************************* */
	initConversations = async () => {
		window.conversationsClient = ConversationsClient;
		this.conversationsClient = new ConversationsClient(this.state.token);

		this.conversationsClient.on("conversationJoined", (conversation) => {
			this.setState((prevState, props) => {
				return { conversations: [...prevState.conversations, conversation] };
			}, this.checkForSpecifiedConversation);
			conversation
				.getMessages()
				.then(async (messagePaginator) => {
					if (messagePaginator.items.length > 0) {
						const conversationPreview = this.createConversationPreview(conversation, messagePaginator.items);
						const response = await this.fetchManualStatus(conversationPreview.phoneNumber);
						if (response.isManualMode) {
							this.addToManualConversations(conversationPreview.id, response.manualEndsTimestamp);
						}
						this.setState((prevState) => {
							return {
								conversationsLoaded: true,
								conversationPreviews: [...prevState.conversationPreviews, conversationPreview].sort((a, b) => b.unixTimestamp - a.unixTimestamp),
							};
						});
					}
				})
				.catch((err) => {
					console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
				});
			conversation.on("messageAdded", (message) => {
				this.removeFromReadConversationsInLocalStorage(conversation.sid);
				this.updateConversationPreview(conversation.sid, message);
			});
		});
	};

	checkForSpecifiedConversation = () => {
		var searchParams = new URLSearchParams(window.location.search);

		const guestPhoneNumber = searchParams.get("guestPhoneNumber");
		if (guestPhoneNumber) {
			const conversation = this.getConversationFromPhoneNumber(guestPhoneNumber);
			if (conversation) {
				const id = conversation.sid;
				const name = conversation.attributes?.guestName ?? "Guest";
				this.displayConversation(id, name, guestPhoneNumber, null);
			}
		}
	};

	updateConversationPreview(conversationId, message, unixTimestamp) {
		let conversationPreviews = this.state.conversationPreviews;
		const index = conversationPreviews.findIndex((preview) => {
			return preview.id === conversationId;
		});
		if (index >= 0) {
			if (message) {
				if (message.state.type === "media") {
					conversationPreviews[index].messageBody = "Attachment: 1 Image";
				} else {
					conversationPreviews[index].messageBody = '"' + message.state.body + '"';
				}
			}
			conversationPreviews[index].unread = !this.getConversationReadStatusFromLocalStorage(conversationId);

			if (unixTimestamp) {
				const options = {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
					month: "short",
					day: "numeric",
					year: "numeric",
				};
				conversationPreviews[index].unixTimestamp = unixTimestamp;
				conversationPreviews[index].timestamp = unixTimestamp?.toLocaleString("en-US", options);
				conversationPreviews.sort((a, b) => b.unixTimestamp - a.unixTimestamp);
			}

			this.setState({
				conversationPreviews: conversationPreviews,
			});
		}
	}

	fetchManualStatus = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("/api/chat/manual-status", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: this.state.hotelId,
			});
			return response?.data;
		} catch (error) {
			console.error("unable to retreive manual status", error);
			return false;
		}
	};

	adjustManualTimeouts = (conversationId, shouldAdd, timestamp) => {
		if (shouldAdd) {
			const now = new Date().getTime();
			const difference = timestamp - now;
			const timeoutId = setTimeout(() => {
				this.removeFromManualConversations(conversationId);
			}, difference);
			this.setState((prevState) => {
				return {
					manualTimeouts: [
						...prevState.manualTimeouts,
						{
							conversationId: conversationId,
							timeoutId: timeoutId,
						},
					],
				};
			}, this.logState);
		} else {
			const pairing = this.state.manualTimeouts.find((pairing) => pairing.conversationId === conversationId);
			if (pairing) {
				this.setState(
					(prevState) => ({
						manualConversationIds: prevState.manualTimeouts.filter((pairing) => pairing.conversationId !== conversationId),
					}),
					this.logState
				);
				clearTimeout(pairing.timeoutId);
			}
		}
	};

	updateManualTimeoutEntryInDatabase = (hotelId, guestPhonenumber, timestamp) => {
		axios
			.post("/api/chat/set-to-manual", {
				hotelId: hotelId,
				guestPhoneNumber: guestPhonenumber,
				timestamp: timestamp.toString(),
			})
			.then((response) => {});
	};

	addToManualConversations = (conversationId, timestamp) => {
		this.setState((prevState) => {
			return { manualConversationIds: [...prevState.manualConversationIds, conversationId] };
		}, this.logState);
		this.adjustManualTimeouts(conversationId, true, timestamp);
	};

	removeFromManualConversations = (conversationId) => {
		this.setState(
			(prevState) => ({
				manualConversationIds: prevState.manualConversationIds.filter((id) => id !== conversationId),
			}),
			this.logState
		);
		this.adjustManualTimeouts(conversationId, false, null);
	};

	toggleManualSwitch = (event) => {
		const shouldAdd = event.target.checked;
		if (shouldAdd) {
			const timestamp = new Date().getTime() + 1 * 60 * 1000 * 15; // TODO: make configurable
			this.addToManualConversations(this.state.currentConversation.sid, timestamp);
			this.updateManualTimeoutEntryInDatabase(this.state.hotelId, this.state.currentGuestPhoneNumber, timestamp);
		} else {
			this.removeFromManualConversations(this.state.currentConversation.sid);
			const now = new Date().getTime();
			this.updateManualTimeoutEntryInDatabase(this.state.hotelId, this.state.currentGuestPhoneNumber, now);
		}
	};

	isInManualMode = () => {
		return this.state.manualConversationIds.includes(this.state.currentConversation.sid);
	};

	logState = () => { };

	createConversationPreview = (conversation, messages) => {
		let conversationPreview = {};
		conversationPreview.id = conversation.sid;
		conversationPreview.name = conversation.attributes?.guestName ?? "Guest";
		conversationPreview.name = conversationPreview.name.replace("null", ""); // Old error results in null appended at the end
		conversationPreview.phoneNumber = conversation.attributes?.guestPhoneNumber ?? "";
		conversation.unread = !this.getConversationReadStatusFromLocalStorage(conversation.sid);
		const lastMessage = messages[messages.length - 1];
		if (lastMessage?.state?.type === "media") {
			conversationPreview.messageBody = "Attachment: 1 Image";
		} else {
			conversationPreview.messageBody = '"' + lastMessage?.state?.body + '"';
		}
		const options = {
			hour: "numeric",
			minute: "numeric",
			hour12: true,
			month: "short",
			day: "numeric",
			year: "numeric",
		};
		const latestTimestamp = lastMessage?.state?.timestamp;
		conversationPreview.unixTimestamp = latestTimestamp;
		conversationPreview.timestamp = latestTimestamp?.toLocaleString("en-US", options);
		return conversationPreview;
	};

	// TODO: read and unread colors
	getConversation = (id) => {
		return this.state.conversations.find((conversation) => conversation.sid == id);
	};

	// Original Patch
	getConversationFromPhoneNumber = (guestPhoneNumber) => {
		// console.log("getConversationFromPhoneNumber", guestPhoneNumber);
		try {
			guestPhoneNumber = guestPhoneNumber.replace(/\s/g, "");

			const guestConversation = this.state.conversations.find((conversation) => {
				return conversation.attributes.guestPhoneNumber.includes(guestPhoneNumber);
			});
			return guestConversation;
		} catch (error) {
			console.error(`Error encountered in getConversationFromPhoneNumber: ${error}`);
		}
	};

	// New Patch
	// getConversationFromPhoneNumber = async (guestPhoneNumber) => {
	// 	console.log("getConversationFromPhoneNumber", guestPhoneNumber);
	// 	try {
	// 		guestPhoneNumber = guestPhoneNumber.replace(/\s/g, "");
	// 		const last10Digits = guestPhoneNumber.slice(-10); // Extract last 10 digits

	// 		const conversation = this.state.conversations.find((conversation) => {
	// 			const phoneNumber = conversation.attributes?.guestPhoneNumber;
	// 			const conversationLast10Digits = phoneNumber && phoneNumber.slice(-10); // Extract last 10 digits from conversation attribute
	// 			return conversationLast10Digits === last10Digits;
	// 		});

	// 		return conversation || null;
	// 	} catch (error) {
	// 		console.error(`Error encountered in getConversationFromPhoneNumber: ${error}`);
	// 		return null;
	// 	}
	// };

	setSearchParams = (key, value) => {
		var searchParams = new URLSearchParams(window.location.search);
		searchParams.set(key, value);
		var newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
		window.history.pushState(null, "", newRelativePathQuery);
	};

	displayConversation = async (id, name, phoneNumber, unixTimestamp) => {
		const currentConversation = this.getConversationFromPhoneNumber(phoneNumber);
		this.setSearchParams("guestPhoneNumber", currentConversation.attributes.guestPhoneNumber);
		this.addToReadConversationsInLocalStorage(id);
		this.updateConversationPreview(id, null, unixTimestamp);
		const allConversationContent = {
			...currentConversation,
			guestName: name,
			guestPhoneNumber: phoneNumber,
			id: id,
		};
		this.setState(
			{
				currentConversation: currentConversation,
				conversationName: name,
				currentGuestPhoneNumber: phoneNumber,
				sideChatData: allConversationContent,
				isSideChatOpen: true,
			},
			async () => {
				const isBlocked = await this.getBlockedStatus(phoneNumber);
				this.setState({ isBlocked });
			}
		);
	};

	getConversationReadStatusFromLocalStorage(conversationId) {
		let readConversations = JSON.parse(localStorage.getItem("marie-read-conversations"));
		if (!readConversations) {
			return false;
		} else {
			return readConversations.includes(conversationId);
		}
	}

	addToReadConversationsInLocalStorage(conversationId) {
		let readConversations = JSON.parse(localStorage.getItem("marie-read-conversations"));
		if (!readConversations) {
			readConversations = [];
		}
		readConversations.push(conversationId);
		localStorage.setItem("marie-read-conversations", JSON.stringify(readConversations));
	}

	removeFromReadConversationsInLocalStorage(conversationId) {
		let readConversations = JSON.parse(localStorage.getItem("marie-read-conversations"));
		if (!readConversations) {
			readConversations = [];
		}
		let index = readConversations.indexOf(conversationId);
		readConversations.splice(index, 1);
		localStorage.setItem("marie-read-conversations", JSON.stringify(readConversations));
	}

	onBackButtonClicked = () => {
		this.setState({ currentConversation: null });
		window.history.pushState(null, "", window.location.pathname);
	};

	handleBlockUnblockClick = (event) => {
		event.preventDefault();
		const guestPhoneNumber = this.state.currentGuestPhoneNumber;
		if (guestPhoneNumber) {
			if (this.state.isBlocked) {
				return this.unblockUser(guestPhoneNumber);
			} else {
				return this.blockUser(guestPhoneNumber);
			}
		}
	};

	blockUser = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("api/chat/block", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: this.state.hotelId,
			});
			if (response.status === 200) {
				this.setState({ isBlocked: true });
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	unblockUser = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("api/chat/unblock", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: this.state.hotelId,
			});
			if (response.status === 200) {
				this.setState({ isBlocked: false });
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	getBlockedStatus = async (guestPhoneNumber) => {
		try {
			const response = await axios.get("api/chat/blocked-status", {
				params: {
					guestPhoneNumber: guestPhoneNumber,
					hotelId: this.state.hotelId,
				},
			});
			if (response.status === 200) {
				return response.data.blocked;
			} else {
				return false;
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	// method to toggle the block overlay
	toggleBlockOverlay = () => {
		this.setState((prevState) => ({ showBlockOverlay: !prevState.showBlockOverlay }));
	};

	closeSideChat = () => {
		this.setState({
			isSideChatOpen: false,
			sideChatData: null,
		});
	};
	//************************************************************************************************************************* */


	fetchRequests = async () => {
		try{
			const response = await axios.get(`/api/requests/${this.state.hotelId}`);
			// TODO: handle non-200s
			const filteredRequests = this.applyFilters(response.data);
			this.setState({
				requests: response.data,
				filteredRequests: filteredRequests
			})

		}catch(err){
			console.error("Couldn't fetch the requests");
		}
	}

	updateRequestStatus = async (guestRequest, status) => {
		try {
			const response = await axios.put('/api/requests', {
				id: guestRequest.id,
				requestStatus: status,
				hotelId: localStorage.getItem('marie_hotel_id')
			});
			if (response.status === 200) {
				const newRequests = this.state.requests.map(request =>
					request.id === guestRequest.id ? { ...request, requestStatus: status } : request
				);
				const filteredRequests = this.applyFilters(newRequests);
				this.setState({
					requests: newRequests,
					filteredRequests: filteredRequests,
				});
			} else {
				this.alertToast();
			}
		} catch (error) {
			this.alertToast();
		}

	}

	alertToast = () => {
		toast((t) => (
			<span onClick={() => toast.dismiss(t.id)}>
				{`Unable to update request`}
			</span>
		), {
			position: 'top-right',
			icon: '⚠️',
			duration: 5000
		});
	}

	successToast = () => {
		toast((t) => (
			<span onClick={() => toast.dismiss(t.id)}>
				{`Message sent!`}
			</span>
		), {
			position: 'top-right',
			icon: '✓',
			style: {
				backgroundColor: '#01a0c6',
				color: '#ffffff',
			},
			duration: 1000
		});
	}
	applyFilters = (requests) => {
		const { activeFilters, activeStatusFilters } = this.state;
		return requests.filter(request =>
			(activeFilters.length === 0 || activeFilters.includes(request.requestCategory)) &&
			(activeStatusFilters.length === 0 || activeStatusFilters.includes(request.requestStatus))
		);
	};
	onRequestFilterChange = (filters) => {
		const activeFilters = filters.filter(filter => filter !== 'INCOMPLETE' && filter !== 'COMPLETE');
		const activeStatusFilters = filters.filter(filter => filter === 'INCOMPLETE' || filter === 'COMPLETE');
		this.setState({ activeFilters, activeStatusFilters }, () => {
			const filteredRequests = this.applyFilters(this.state.requests);
			this.setState({ filteredRequests: filteredRequests });
		});
	};

	handleToggleOptions = () => {
		this.setState({
			openOptions: !this.state.openOptions
		});
	}
	openModalCloseOptions = (messageType) => {
		this.handleOpenModal(messageType);
		this.handleOptionsOff();
	}
	handleOptionsOff = () => {
		this.setState({
			openOptions: false
		})
	}
	handleFormSent = () => {
		this.handleCloseModal()
		this.successToast()
	}

	setCurrentPage = (page) => {
		this.setState({ currentPage: page });
	};
	renderWhichModalMessageType = (messageType) => {
		switch(messageType) {
			case 'blast':
				return (
					<Modal showModal={this.state.showModal} closeModal={this.handleCloseModal}>
						<BlastMessageForm onFormSent={this.handleFormSent}></BlastMessageForm>
					</Modal>
				)
			case 'custom':
				return (
					<Modal showModal={this.state.showModal} closeModal={this.handleCloseModal}>
						<CustomMessageForm onFormSent={this.handleFormSent}></CustomMessageForm>
					</Modal>
				)
		}
	}
	render() {
		return (
			<>
				{this.renderWhichModalMessageType(this.state.messageType)}
				<div className="requests-panel-body">
					<div className="requests-panel-content">
						<div className="inbox-panel-header">
							<div className="requests-panel-title">Inbox</div>
							<div className='option-button-container'>
								<img
									id="custom-message-button"
									className="custom-message-button"
									onClick={this.handleToggleOptions}
									src={customMessageButton}>
								</img>
								{this.state.openOptions ? (
									<>
										<div className='open-options-ctn'>
											<div className='message-icon-ctn'>
												<img className='message-icon' src={customMessageIcon}></img>
												<div className='open-options' onClick={() => this.openModalCloseOptions('custom')}>
													Send Custom Message
												</div>
											</div>
											<div className='message-icon-ctn'>
												<img className='message-icon' src={blastMessageIcon}></img>
												<div className='open-options' onClick={() => this.openModalCloseOptions('blast')}>
													Send Blast Message
												</div>
											</div>
										</div>
									</>
								): (
									<></>
								)}
							</div>
						</div>
						<RequestsFilter 
							onChange={this.onRequestFilterChange}
						/>
						<div className="requests-list-body" key={this.state} >
							{this.state.filteredRequests
								.slice((this.state.currentPage - 1) * LIST_SIZE, this.state.currentPage * LIST_SIZE)
								.map(request => {
									return <GuestRequest
										request={request}
										updateRequestStatus={this.updateRequestStatus}
										key={request.id}
										render_key={this.state.render_key}
										displayConversation={this.displayConversation}>
									</GuestRequest>
								})}

							{
								this.state.filteredRequests.length ?
								<PaginationFooter
								currentPage={this.state.currentPage}
								setCurrentPage={this.setCurrentPage}
								numberOfTotalPreviews={this.state.filteredRequests.length}
								numberOfPreviews={this.state.currentPage}
								pageSize={12}
								/>
								: null
							}
						</div>
					</div>
					{this.state.isSideChatOpen && (
							<SideChat
								data={this.state.sideChatData}
								arrivalData={this.props.arrivalData}
								hotelId={this.state.hotelId}
								conversation={this.state.currentConversation}
								guestPhoneNumber={this.state.currentGuestPhoneNumber}
								constructor={"conversations"}
								isOpen={this.state.isSideChatOpen}
								toggleSideChat={this.closeSideChat}
								addToManualConversations={this.addToManualConversations}
								removeFromManualConversations={this.removeFromManualConversations}
								toggleManualSwitch={this.toggleManualSwitch}
								isInManualMode={this.isInManualMode}
								toggleBlockOverlay={this.toggleBlockOverlay}
								handleBlockUnblockClick={this.handleBlockUnblockClick}
								showBlockOverlay={this.state.showBlockOverlay}
								onBackButtonClicked={this.onBackButtonClicked}
								isBlocked={this.state.isBlocked}
							/>
					)}
				</div>
			</>
		);
	}
}

export default RequestsPanel;