import React from 'react';
import './style.scss';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import RequestsPanel from '../../components/RequestsPanel/RequestsPanel';


function RequestsPage({ chatToken, hotelId }) {
  return (
    <div className="dashboard-body">
      <ControlPanel page="requests"></ControlPanel>
      <RequestsPanel chatToken={chatToken} hotelId={hotelId}></RequestsPanel>
    </div>
  );
}

export default RequestsPage;
