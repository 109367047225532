import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import InboxPage from './pages/InboxPage/InboxPage'
import RequestsPage from './pages/RequestsPage/RequestsPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import ArrivalsPage from './pages/ArrivalsPage/ArrivalsPage';
import ReviewsPage from './pages/ReviewsPage/ReviewsPage';
import LeadsPage from './pages/LeadsPage/LeadsPage';
import { useState, useEffect } from 'react';
import axios from 'axios';
import loadingSpinner from './assets/eclipse-1s-200px.gif'
import toast, { Toaster } from 'react-hot-toast';

function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [chatToken, setChatToken] = useState();
  const [hotelId, setHotelId] = useState();
  const [reviewsPageDisabled, setReviewsPageDisabled] = useState(true);
  const [leadsPageDisabled, setLeadsPageDisabled] = useState(true);

  const parseHash = () => {
    var hash = window.location.hash.substring(1);
    var params = {}
    hash.split('&').map(hk => { 
      let temp = hk.split('='); 
        params[temp[0]] = temp[1] 
    });
    return params
  }

  useEffect(() => {
    async function redirectUrls(){
      const urlList = JSON.parse(process.env.REACT_APP_REDIRECT_URLS);
        for (const ending in urlList){
          if(window.location.href.endsWith(ending)){
            window.location.href = urlList[ending];
          }
        }
    }
    async function fetchData() {
      await redirectUrls();
      const params = parseHash()
      let access_token
      if (params.access_token) {
        access_token = params.access_token
        localStorage.setItem('marie_access_token', access_token)
      } else {
        access_token = localStorage.getItem('marie_access_token', access_token)
      }
      try {
        const response = await axios.post('/api/auth/login', {
          access_token: access_token
        });
        if (response.status == 200) {
          setAuthenticated(true)
          setChatToken(response.data.chatToken)
          setHotelId(response.data.hotelId)
          localStorage.setItem('marie_hotel_id', response.data.hotelId);
          try {
            const hotel = localStorage.getItem('marie_hotel_id');
            const response = await axios.get(`/api/hotel/${hotel}/phone-number`);
            localStorage.setItem('marie_hotel_phone_number', response.data.hotelPhoneNumber)
          } catch (error) {
            console.error(error)
          }
        }
      } catch (error) {
        if (error.response.status == 403) {
          window.location.href = error.response.data.redirectUrl
        }
      }
    }
    fetchData();
  }, []);


  return (
    <div className="app-body">
      <Toaster toastOptions={{
          className: 'reminder-toast',
        }}/>
      {isAuthenticated ?
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to="/requests" /> }/>
          <Route path="inbox" element={<InboxPage chatToken={chatToken} hotelId={hotelId}></InboxPage>} />
          <Route path="reviews" element={<ReviewsPage chatToken={chatToken} hotelId={hotelId}></ReviewsPage>}/>
          <Route path="requests" element={<RequestsPage chatToken={chatToken} hotelId={hotelId}></RequestsPage>}/>
          <Route path="leads" element={<LeadsPage chatToken={chatToken} hotelId={hotelId}></LeadsPage>} />
          <Route path="settings" element={<SettingsPage chatToken={chatToken} hotelId={hotelId}></SettingsPage>} />
          <Route path="arrivals" element={<ArrivalsPage chatToken={chatToken} hotelId={hotelId}></ArrivalsPage>} />
        </Routes>
      </BrowserRouter> :
      <div className="loading-container">
        <img src={loadingSpinner} className="loading-spinner"></img>
      </div>}
    </div>
  );
}

export default App;
